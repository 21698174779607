<template>
	<div id="delete-user">
		<div v-if="serverStatus.serverBusy" id="delete-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ serverStatus.busyText }}</span>
		</div>
		<div>
			<h3>Delete User Account</h3>
			<p></p>
			<button class="btn red-bg button-group" @click="deleteUserSafetyCheck()">Delete User</button>
		</div>
		<transition name="fade">
			<component
				v-bind:is="currentModal"
				:adminState="adminState"
				:safetyCheckData="safetyCheckData"
				:selectedUser="selectedUser"
				:serverStatus="serverStatus"
				:systemCurrencyTool="systemCurrencyTool"
			/>
		</transition>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import DeleteUserModal from "@/components/DeleteUserModal";

export default {
	name: "DeleteUser",
	inheritAttrs: false,
	components: {
		DeleteUserModal,
	},
	props: {
		adminState: Object,
		selectedUser: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverStatus: {
				serverBusy: false,
				busyText: "",
			},
			currentModal: null,
			safetyCheckData: {},
			userId: this.selectedUser.id,
		};
	},
	watch: {},
	created() {
		this.eventBus.on("executeDeleteUser", () => {
			this.executeDeleteUser();
		});
		this.eventBus.on("closeDeleteUserModal", () => {
			this.currentModal = null;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("executeDeleteUser");
			this.eventBus.off("closeDeleteUserModal");
		});
	},
	mounted() {},
	methods: {
		async deleteUserSafetyCheck() {
			// Check component's if server is not already refreshing access token. Return if true.
			if (this.refreshTokenAwait) return;
			this.serverStatus.busyText = `Safety checking before deleting ${this.selectedUser.displayName}.`;
			this.serverStatus.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/user/${this.selectedUser.id}/delete`, this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverStatus.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.safetyCheckData = dataJson;

				this.currentModal = "DeleteUserModal";

				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
			} catch (e) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async executeDeleteUser() {
			this.busyText = `Deleting ${this.safetyCheckData.displayName} user account`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.safetyCheckData.userId,
			};

			let requestUrl = new URL("/api/v1/user/delete", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				if (response.ok !== true) {
					this.status.ok = false;
					this.status.message = `Something went wrong. Please try again later.`;
					this.eventBus.emit("updateStatus", this.status);
					this.currentModal = null;
					this.eventBus.emit("deselectUser");
					return false;
				}

				this.status.ok = true;
				this.status.message = `${this.safetyCheckData.displayName} has been deleted.`;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("deselectUser");
				this.currentModal = null;

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-group {
	display: block;
	margin: 15px auto 0;
}
</style>
