<template>
	<div id="user-details" v-if="selectedUser?.phoneNumber">
		<h2>{{ selectedUser?.displayName }} Details</h2>
		<div class="user-details-container">
			<div v-if="!!serverBusy" id="details-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<table class="user-list">
				<tr class="">
					<th>Display Name</th>
					<td>{{ selectedUser.displayName }}</td>
				</tr>
				<tr class="">
					<th>User Phone Number</th>
					<td>
						{{
							systemSettings?.allowPhoneExtensionDigits && phoneNumberParsed?.possibility !== "is-possible"
								? selectedUser?.phoneNumber
								: phoneNumberParsed?.number?.national
						}}
					</td>
				</tr>
				<tr class="">
					<th>User ID</th>
					<td>{{ selectedUser.id }}</td>
				</tr>
				<tr class="">
					<th>User Phone Number Verified</th>
					<td>{{ selectedUser.isUserVerified }}</td>
				</tr>
				<tr class="">
					<th>User Email Verification Status</th>
					<td>{{ selectedUser?.emailVerificationStatus }}</td>
				</tr>
				<tr class="">
					<th>Last Login</th>
					<td>{{ lastLogin }}</td>
				</tr>
				<tr class="">
					<th>Lock Status</th>
					<td :class="selectedUser?.lockStatus !== 'NotLocked' ? 'red-bg white-text' : ''">
						{{ camelToTitleCase(selectedUser?.lockStatus) }}
					</td>
				</tr>
			</table>
		</div>
		<div v-if="adminState?.isSysadmin" id="id-check-review-container">
			<button
				class="btn"
				:title="`${showIdCheckReview ? 'Hide' : 'Show'} Id Check Review`"
				@click="showIdCheckReview = showIdCheckReview ? false : true"
			>
				{{ `${showIdCheckReview ? "Hide" : "Show"} Id Check Review` }}
			</button>
			<IdCheckReview v-if="showIdCheckReview" :adminState="adminState" :selectedUser="selectedUser" />
		</div>
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
</template>

<script>
import IdCheckReview from "@/components/IdCheckReview";

// Tools from awesome-phonenumber. For now were just using parsePhoneNumber
// https://www.npmjs.com/package/awesome-phonenumber
import {
	parsePhoneNumber,
	// getNumberFrom,
	// getExample,
	// getCountryCodeForRegionCode,
	// getRegionCodeForCountryCode,
	// getSupportedCallingCodes,
	// getSupportedRegionCodes,
	// getAsYouType,
} from "awesome-phonenumber";

export default {
	name: "UserDetails",
	components: {
		IdCheckReview,
	},
	props: {
		adminState: Object,
		selectedUser: Object,
		isMobile: Boolean,
		systemSettings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			lastLogin: new Date(this.selectedUser.lastLoginDate).toLocaleTimeString([], this.dateOptions),
			serverBusy: false,
			phoneNumberParsed: "",
			showIdCheckReview: false,
		};
	},
	watch: {
		selectedUser: {
			handler() {
				if (this.selectedUser?.lastLoginDate && new Date(this.selectedUser.lastLoginDate).getTime() > 0) {
					this.lastLogin = new Date(this.selectedUser.lastLoginDate).toLocaleTimeString([], this.dateOptions);
				} else {
					this.lastLogin = "No login records found.";
				}
				this.formatPhoneInternational();
			},
			deep: true,
		},
	},
	methods: {
		formatPhoneInternational() {
			let phone = `+${this.selectedUser.phoneCountryCode}${this.selectedUser.phoneNumber}`;
			this.phoneNumberParsed = parsePhoneNumber(phone);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#details-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

.btn {
	display: block;
	margin: 15px auto 5px;
}

.user-message {
	margin-top: 15px;
}

.user-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.user-list th,
.user-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.user-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
