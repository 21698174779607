<template>
	<div class="user-permissions-container">
		<table class="permissions-list" v-if="sitePermissionsArray.length > 0">
			<tr class="header-row">
				<th>Casino Name</th>
				<th>Site Permissions</th>
			</tr>
			<tr v-for="(item, index) in sitePermissionsArray" :key="index">
				<td>{{ item.casinoName }}</td>
				<td>{{ item.permissions }}</td>
			</tr>
		</table>
		<h3 v-else>No Site Permissions</h3>
		<button id="add-site-permissions" class="add btn" :title="buttonText" @click="addSitePermissions()">{{ buttonText }}</button>
		<div v-if="openPermisionEditor">
			<h3>Site Permissions</h3>
			<div class="add-site-permissions">
				<div v-if="adminState.isSysadmin || (adminState.isHelpDesk || adminState?.isManager && casinoListForUser.length > 1)">
					<label for="casinoId">Casino</label>
					<select title="Select Casino" id="casinoId" v-model="selectedCasinoId">
						<option v-for="(item, index) in casinoListForUser" :key="index" :value="item.id">{{ item.name }}</option>
					</select>
				</div>
				<div v-else>
					<h3>Permissions for {{ casinoName }}</h3>
				</div>
				<div class="permissions-check" v-if="selectedCasinoId">
					<div>
						<label for="cashierPermissions">Cashier</label>
						<input type="checkbox" id="cashierPermissions" v-model="cashierPermissions" />
					</div>
					<div>
						<label for="reporterPermissions">Reporter</label>
						<input type="checkbox" id="reporterPermissions" v-model="reporterPermissions" />
					</div>
					<div>
						<label for="managerPermissions">Manager</label>
						<input type="checkbox" id="managerPermissions" v-model="managerPermissions" />
					</div>
					<div>
						<label for="siteAdminPermissions">SiteAdmin</label>
						<input type="checkbox" id="siteAdminPermissions" v-model="siteAdminPermissions" />
					</div>
				</div>
				<h3 v-else>Select a casino to edit permissions</h3>
				<button v-if="selectedCasinoId" class="submit btn" title="Submit" @click="submitSitePermissions()">Submit</button>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";

export default {
	name: "SitePermissions",
	props: {
		adminState: Object,
		casinoList: Array,
		selectedUser: Object,
		sitePermissionsArray: Array,
		serverBusy: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			openPermisionEditor: false,
			casinoListForUser: this.casinoList,
			casinoId: null,
			casinoName: !this.adminState.isSysadmin && !this.adminState.isHelpDesk ? this.adminState.casinoName : "",
			permissions: null,
			buttonText: "Edit Site Permissions",
			cashierPermissions: null,
			reporterPermissions: null,
			siteAdminPermissions: null,
			managerPermissions: null,
			playerPermissions: null,
			sysadminPermissions: null,
			helpDeskPermissions: null,
			selectedCasinoId: !this.adminState.isSysadmin && !this.adminState.isHelpDesk ? this.adminState.casinoId : null,
		};
	},
	updated() {
		let thisInstance = this;
		this.$nextTick(() => {
			// Code runs only after the view has been re-rendered
			if (thisInstance.openPermisionEditor) {
				let add = document.getElementById("add-site-permissions");
				add.scrollIntoView({ behavior: "smooth" });
			} else {
				let global = document.getElementById("tab-ui");
				global.scrollIntoView({ behavior: "smooth" });
			}
		});
	},
	watch: {
		selectedUser: {
			handler() {
				this.populatePermissionsOptions();
				if (!this.adminState.isSysadmin && !this.adminState.isHelpDesk) this.listCasinosForThisUser();
			},
			deep: true,
		},
		openPermisionEditor() {
			this.buttonText = this.openPermisionEditor ? "Close Permissions Editor" : "Edit Site Permissions";
		},
		selectedCasinoId() {
			this.populatePermissionsOptions();
		},
	},
	created() {
		let thisInstance = this;
		if (!this.adminState.isSysadmin && !this.adminState.isHelpDesk) this.listCasinosForThisUser();
		this.eventBus.on("deselectUser", () => {
			thisInstance.openPermisionEditor = false;
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("deselectUser");
		});
	},
	mounted() {
		this.populatePermissionsOptions();
	},
	methods: {
		listCasinosForThisUser() {
			let permissionsList = this.adminState?.userPermissions?.sitePermissions;
			let list = [];
			for (const key in permissionsList) {
				if (permissionsList[key].includes("SiteAdmin") || permissionsList[key].includes("Manager")) {
					let casino = this.casinoList.filter((casino) => casino.id == key)[0];
					list.push(casino);
				}
			}

			this.casinoListForUser = list;
		},
		addSitePermissions() {
			this.openPermisionEditor = this.openPermisionEditor ? false : true;
		},
		populatePermissionsOptions() {
			let permissions = this.selectedUser?.permissions?.sitePermissions[this.selectedCasinoId];
			this.cashierPermissions = permissions?.includes("Cashier");
			this.reporterPermissions = permissions?.includes("Reporter");
			this.siteAdminPermissions = permissions?.includes("SiteAdmin");
			this.managerPermissions = permissions?.includes("Manager");
		},
		submitSitePermissions() {
			let newPermissions = {};

			if (!this.selectedCasinoId) {
				this.status.message = "You must select a casino from the drop down list";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				return false;
			}

			let siteArray = [];
			if (this.cashierPermissions) siteArray.push("Cashier");
			if (this.reporterPermissions) siteArray.push("Reporter");
			if (this.siteAdminPermissions) siteArray.push("SiteAdmin");
			if (this.managerPermissions) siteArray.push("Manager");
			if (!siteArray.length > 0) siteArray = null;
			newPermissions = {
				sitePermissions: {
					[this.selectedCasinoId]: siteArray ? siteArray.join(", ") : "none",
				},
			};

			let payload = {
				newPermissions: newPermissions,
				selectedCasinoId: this.selectedCasinoId,
				sitePermissions: true,
			};
			this.eventBus.emit("submitPermissionsChange", payload);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.permissions-list {
	width: 100%;
	margin: 30px auto 15px;
	text-align: left;
	border-collapse: collapse;
}

th,
td {
	border: 1px #000 solid;
	padding: 5px 10px;
	width: 50%;
}

tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.add-site-permissions {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	user-select: none;
}

.permissions-check {
	display: flex;
	flex-flow: row wrap;
}

.permissions-check > div {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.permissions-check label,
.permissions-check input {
	cursor: pointer;
}

.checkbox-button input {
	align-self: center;
}
</style>
