<template>
	<div id="user-password-reset">
		<h3>Reset Password for {{ selectedUser.displayName }}</h3>
		<h6 class="last-login">last Login: {{ lastLogin }}</h6>
		<div v-if="!!serverBusy" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div v-else class="password-reset-container">
			<div>
				<label for="noteText">Password Reset Note (optional)</label>
				<textarea
					type="text"
					name="noteText"
					placeholder="Enter Password Reset Note"
					title="Enter Password Reset Note"
					v-model="noteText"
				></textarea>
			</div>
			<button class="submit btn" title="Reset Password" @click="showResetDialog()">Reset Password</button>
		</div>
	</div>
	<dialog id="reset-dialog">
		<div>
			<p id="reset-text">
				{{ `Are you sure you want to reset password for, ${this.selectedUser.displayName}?\nThis will deactivate current password for ${this.selectedUser.displayName}.`}}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="submitPasswordReset()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeDialog()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
export default {
	name: "UserPasswordReset",
	inheritAttrs: false,
	props: {
		adminState: Object,
		selectedUser: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			noteText: "",
			lastLogin: new Date(this.selectedUser.lastLoginDate).toLocaleTimeString([], this.dateOptions),
			inputDialog: null,
		};
	},
	watch: {
		selectedUser: {
			handler() {
				if (this.selectedUser?.lastLoginDate && new Date(this.selectedUser.lastLoginDate).getTime() > 0) {
					this.lastLogin = new Date(this.selectedUser.lastLoginDate).toLocaleTimeString([], this.dateOptions);
				} else {
					this.lastLogin = "This user has never logged on";
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.inputDialog = document.getElementById("reset-dialog");
	},
	methods: {
		async submitPasswordReset() {
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				this.closeDialog();
				return false;
			}

			let body = {
				UserId: this.selectedUser.id,
				NoteText: this.noteText,
			};

			let requestUrl = new URL("/api/v1/user/password/reset", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.getDetailsComplete = true;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					this.closeDialog();
					return false;
				}

				this.status.message = await response.json();
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
				this.closeDialog();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				this.closeDialog();
				return false;
			}
		},

		showResetDialog() {
			this.inputDialog.showModal();
		},

		closeDialog() {
			this.inputDialog.close();
		}
		
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.last-login {
	text-align: center;
	margin: -10px 10px 5px;
}

.password-reset-container > div {
	display: flex;
	flex-direction: column;
}

textarea {
	margin: 5px;
}

#reset-dialog {
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	justify-content: center;
}

#reset-text {
	text-align: center;
}
</style>
