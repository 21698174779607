<template>
	<table class="transfers-list">
		<tr class="sticky">
			<th>Transaction Date</th>
			<th>Cashier Initials</th>
			<th>Cash</th>
			<th>Promo</th>
		</tr>
		<tr v-for="(item, index) in userTransfers" :key="index" :value="item.id">
			<td>{{ item?.date }}</td>
			<td class="cashier-id">
				<span :style="getUserIconColors(item?.cashierUserId)">{{ item?.cashierInitials }}</span>
			</td>
			<td
				:class="item?.toPlayerAmount.regularAU !== 0 && Math.sign(item?.toPlayerAmount.regularAU) !== 1 ? 'cash-out' : ''"
				class="cash-display"
			>
				{{ systemCurrencyTool.formatFromAU(item?.toPlayerAmount.regularAU, systemFull) }}
			</td>
			<td :class="item?.toPlayerAmount.promoAU !== 0 && Math.sign(item?.toPlayerAmount.promoAU) !== 1 ? 'cash-out' : ''" class="cash-display">
				{{ systemCurrencyTool.formatFromAU(item?.toPlayerAmount.promoAU, systemFull) }}
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: "UserTransfersTable",
	inheritAttrs: false,
	props: {
		userTransfers: Array,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemWholeOrFull: this.systemCurrencyTool.displayType.wholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	methods: {
		getUserIconColors(userId) {
			let style = `background-color: #${this.generateUserIdColor(userId).background}; color: #${this.generateUserIdColor(userId).color}`;
			return style;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transfers-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.transfers-list th,
.transfers-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.transfers-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.cash-out {
	color: #0034c7;
	font-weight: bold;
}
</style>
