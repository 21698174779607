<template>
	<div id="user-manager">
		<h1>User Manager</h1>
		<span class="user-account-info" v-if="selectedUser?.phoneNumber">
			<!-- <div>User Account</div> -->
			<span>{{ selectedUser?.displayName }}</span>
			<span>
				{{
					systemSettings?.allowPhoneExtensionDigits && phoneNumberParsed?.possibility !== "is-possible"
						? selectedUser?.phoneNumber
						: phoneNumberParsed?.number?.national
				}}
			</span>
			<button v-if="selectedUser?.phoneNumber" @click="eventBus.emit('deselectUser')" title="Deselect User" class="deselect">
				Deselect User
			</button>
		</span>
		<div id="user-list">
			<div v-if="!!serverBusy" id="user-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<div class="user-list-container" v-if="!selectedUser?.phoneNumber">
				<div>
					<div class="user-buttons-flex">
						<div class="checkbox-button">
							<div>
								<label for="hasAccountAtCasino" title="Has player account at this casino">Has player account at this casino</label>
								<input
									type="checkbox"
									id="hasAccountAtCasino"
									title="Has player account at this casino"
									v-model="hasAccountAtCasino"
								/>
							</div>
							<div>
								<label for="hasPermissionsAtCasino" title="Has permissions this casino">Has permissions this casino</label>
								<input
									type="checkbox"
									id="hasPermissionsAtCasino"
									title="Has permissions this casino"
									v-model="hasPermissionsAtCasino"
								/>
							</div>
							<div>
								<label for="includeDeletedUsers" title="Include Deleted Users">Include Deleted Users</label>
								<input type="checkbox" id="includeDeletedUsers" title="Include Deleted Users" v-model="includeDeletedUsers" />
							</div>
						</div>
						<input
							type="tel"
							pattern="[0-9]+"
							title="Phone Number Starts With"
							placeholder="Phone Number Starts With"
							v-model="phoneNumberStartsWith"
							@keyup.enter="lookUpUserByPhone()"
						/>
						<button @click="lookUpUserByPhone()" title="Search">Search</button>
					</div>
					<div id="paging">
						<label title="Set Limit" for="limitOptions">Limit</label>
						<select v-model="limit" id="limitOptions">
							<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
						</select>
						<button class="prev-button" type="button" title="Previous" @click="previousPage()">Previous</button>
						<button class="next-button" type="button" title="Next" @click="nextPage()">Next</button>
						<span v-if="currentPage" :currentPage="currentPage">page {{ currentPage }}</span>
					</div>
					<table class="user-list" v-if="userList.length > 0">
						<tr class="header-row">
							<th>User Phone Number</th>
							<th>Display Name</th>
						</tr>
						<tr
							:id="item.id"
							class="data-row"
							:key="index"
							v-for="(item, index) in userList"
							@click="selectUser(item.id)"
							:class="item.deletedDate ? 'yellow-bg' : ''"
							:title="item.deletedDate ? 'Deleted User' : ''"
						>
							<td>{{ formatPhoneInternationalListItem(item) }}</td>
							<td>{{ item.displayName }}</td>
						</tr>
					</table>
					<div v-else-if="!userList.length > 0 && (hasAccountAtCasino || hasPermissionsAtCasino || didSearch)">
						<h3 v-if="currentPage">No Matches Found</h3>
					</div>
				</div>
			</div>
			<div id="tab-ui" :class="isMobile && !selectedUser?.phoneNumber ? 'hidden' : ''">
				<ul>
					<li
						title="User Details"
						id="UserDetails"
						@click="currentTab = 'UserDetails'"
						:class="currentTab == 'UserDetails' ? 'active' : ''"
					>
						User Details
					</li>
					<li
						v-if="adminState?.isSiteAdmin || adminState?.isCashier || adminState?.isHelpDesk || adminState?.isManager"
						title="Funds &amp; Transfers"
						id="UserTransfers"
						@click="currentTab = 'UserTransfers'"
						:class="currentTab == 'UserTransfers' ? 'active' : ''"
					>
						Funds &amp; Transfers
					</li>
					<li
						v-if="
							(adminState?.isSysadmin || adminState?.isSiteAdmin || adminState?.isHelpDesk || adminState?.isManager) &&
							!selectedUser?.deletedDate
						"
						title="User Actions"
						id="UserActions"
						@click="currentTab = 'UserActions'"
						:class="currentTab == 'UserActions' ? 'active' : ''"
					>
						User Actions
					</li>
					<li
						v-if="
							adminState?.isSysadmin ||
							adminState?.isSiteAdmin ||
							adminState?.isCashier ||
							adminState?.isHelpDesk ||
							adminState?.isReporter ||
							adminState?.isManager
						"
						title="User Audit Logs"
						id="UserAuditLogs"
						@click="currentTab = 'UserAuditLogs'"
						:class="currentTab == 'UserAuditLogs' ? 'active' : ''"
					>
						User Audit Logs
					</li>
					<li
						v-if="adminState?.isSysadmin || adminState?.isSiteAdmin || adminState?.isHelpDesk || adminState?.isManager"
						title="Play Activity"
						id="PlayActivity"
						@click="currentTab = 'PlayActivity'"
						:class="currentTab == 'PlayActivity' ? 'active' : ''"
					>
						Play Activity
					</li>
					<li
						v-if="selectedUser?.lockStatus === 'NotLocked' && (adminState?.isSiteAdmin || adminState?.isManager || adminState?.isManager)"
						title="MRU Resolution"
						id="MruResolution"
						@click="currentTab = 'MruResolution'"
						:class="currentTab == 'MruResolution' ? 'active' : ''"
					>
						MRU Resolution
					</li>
				</ul>
				<div id="tab-contents">
					<keep-alive>
						<component
							v-bind:is="currentTab"
							:adminState="adminState"
							:casinoList="casinoList"
							:selectedUser="selectedUser"
							:isMobile="isMobile"
							:inGameCurrencyTool="inGameCurrencyTool"
							:systemCurrencyTool="systemCurrencyTool"
							:systemSettings="systemSettings"
						/>
					</keep-alive>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store/index";
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import UserDetails from "@/components/UserDetails";
import UserTransfers from "@/components/UserTransfers";
import UserActions from "@/components/UserActions";
import UserAuditLogs from "@/components/UserAuditLogs";
import PlayActivity from "@/components/PlayActivity";
import MruResolution from "@/components/MruResolution.vue";

// Tools from awesome-phonenumber. For now were just using parsePhoneNumber
// https://www.npmjs.com/package/awesome-phonenumber
import {
	parsePhoneNumber,
	// getNumberFrom,
	// getExample,
	// getCountryCodeForRegionCode,
	// getRegionCodeForCountryCode,
	// getSupportedCallingCodes,
	// getSupportedRegionCodes,
	// getAsYouType,
} from "awesome-phonenumber";

export default {
	name: "UserManager",
	components: {
		UserDetails,
		UserTransfers,
		UserActions,
		UserAuditLogs,
		PlayActivity,
		MruResolution,
	},
	props: {
		adminState: Object,
		casinoList: Array,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		systemSettings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			setcurrentCompnentWrapper: null,
			serverBusy: false,
			didSearch: false,
			busyText: "",
			phoneNumberStartsWith: "",
			phoneNumber: "",
			userId: "",
			userList: [],
			hasAccountAtCasino: false,
			hasPermissionsAtCasino: false,
			offset: null,
			limit: this.isMobile ? 5 : 10,
			currentPage: null,
			isLastPage: false,
			selectedUser: {},
			currentTab: "UserDetails",
			phoneNumberParsed: "",
			includeDeletedUsers: false,
		};
	},
	watch: {
		hasAccountAtCasino() {
			this.currentPage = 1;
			this.offset = 0;
			this.resetPhoneNumberList();
		},
		hasPermissionsAtCasino() {
			this.currentPage = 1;
			this.offset = 0;
			this.resetPhoneNumberList();
		},
		includeDeletedUsers() {
			this.currentPage = 1;
			this.offset = 0;
			this.resetPhoneNumberList();
		},
		phoneNumberStartsWith() {
			this.currentPage = 1;
			this.offset = 0;
			this.didSearch = false;
		},
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.lookUpUserByPhone();
		},
		selectedUser: {
			handler() {
				if (this.selectedUser?.deletedDate !== null) this.currentTab = "UserDetails";
				if (this.selectedUser?.phoneNumber) this.formatPhoneInternational();
			},
			deep: true,
		},
	},
	created() {
		this.eventBus.on("updateSelectedUser", (payload) => {
			this.selectedUser = payload || {};
			store.dispatch("setSelectedUser", this.selectedUser);
		});
		this.eventBus.on("deselectUser", () => {
			this.deselectUser();
			this.resetPhoneNumberList();
			this.currentPage = null;
			this.offset = null;
			this.limit = this.isMobile ? 5 : 10;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("updateSelectedUser");
			this.eventBus.off("deselectUser");
		});
	},
	mounted() {
		this.serverBusy = false;
	},
	methods: {
		formatPhoneInternational() {
			let phone = `+${this.selectedUser.phoneCountryCode}${this.selectedUser.phoneNumber}`;
			this.phoneNumberParsed = parsePhoneNumber(phone);
		},
		formatPhoneInternationalListItem(user) {
			let phone = `+${user.phoneCountryCode}${user.phoneNumber}`;
			let parsed = parsePhoneNumber(phone);

			let returnedPhone =
				this.systemSettings?.allowPhoneExtensionDigits && parsed?.possibility !== "is-possible"
					? user?.phoneNumber
					: parsed?.number?.national;

			return returnedPhone;
		},
		resetPhoneNumberList() {
			this.userList = [];
			// this.currentTab = this.selectedUser?.deletedDate ? "UserDetails" : "UserActions";
			if (this.phoneNumberStartsWith || this.hasAccountAtCasino || this.hasPermissionsAtCasino || this.includeDeletedUsers)
				this.lookUpUserByPhone();
		},
		deselectUser() {
			this.selectedUser = {};
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.lookUpUserByPhone();
		},
		nextPage() {
			if (this.isLastPage) return;
			this.currentPage = this.currentPage ? this.currentPage : this.currentPage + 1;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.lookUpUserByPhone();
		},
		async lookUpUserByPhone() {
			if (this.phoneNumberStartsWith.match(/[^0-9]/)) {
				this.status.message = "Phone number must be numbers only.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return this.status;
			}
			this.didSearch = false;
			this.busyText = "Loading Users";
			this.serverBusy = true;

			// get one more item than requested to see if a second page exists
			let currentLimit = this.limit + 1;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/user", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.offset) params.set("offset", this.offset);
			if (this.limit) params.set("limit", currentLimit);
			if (this.phoneNumberStartsWith) params.set("phoneNumberStartsWith", this.phoneNumberStartsWith);
			if (this.hasAccountAtCasino) params.set("hasAccountAtCasinoId", this.adminState.casinoId);
			if (this.hasPermissionsAtCasino) params.set("hasPermissionsAtCasinoId", this.adminState.casinoId);
			if (this.includeDeletedUsers) params.set("includeDeleted", this.includeDeletedUsers);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.isLastPage = dataJson?.length <= this.limit;
				// remove extra item if a next page exists
				if (!this.isLastPage) dataJson.pop();

				this.userList = dataJson;
				this.serverBusy = false;
				this.didSearch = true;

				if (dataJson.length == 1) {
					this.userId = dataJson[0].id;
					this.phoneNumber = dataJson[0].phoneNumber;
				} else {
					this.userId = "";
				}
				this.currentPage = this.currentPage ? this.currentPage : this.currentPage + 1;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
				this.serverBusy = false;
			}
		},
		selectUser(selectedUserId) {
			let allRows = document.getElementsByClassName("data-row");
			allRows = Array.from(allRows);
			allRows.forEach((row) => {
				row.classList.remove("selected");
			});
			let selectedRow = document.getElementById(selectedUserId);
			selectedRow.classList.add("selected");
			let thisInstance = this;
			if (this.userList.length == 1) {
				this.selectedUser = this.userList[0];
			} else {
				this.userList.forEach((item) => {
					if (item.id == selectedUserId) thisInstance.selectedUser = item;
				});
			}
			this.eventBus.emit("updateSelectedUser", this.selectedUser);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#user-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

.user-message {
	margin-top: 15px;
}

.data-row {
	cursor: pointer;
	user-select: none;
}

.data-row.selected,
.user-list .data-row:hover {
	background-color: #5b88c0;
}

.user-list .header-row {
	background-color: #000;
}

#user-list {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	height: auto;
	overflow: hidden auto;
	margin-bottom: 30px;
}

.user-list {
	width: 100%;
	margin: 15px auto 0;
	text-align: center;
	background-color: #424242;
}

.user-list .data-row {
	border: 1px #5b88c0 solid;
}

.user-list .data-row td {
	padding: 10px 0;
}

.user-list-container {
	flex: auto;
	background-color: black;
	margin: 15px;
	border-radius: 12px;
}

#paging {
	margin-top: 15px;
}

.checkbox-button > div {
	display: flex;
	justify-content: space-between;
}

.user-list-container > div {
	padding: 15px;
}

#tab-ui {
	flex: 1 1 auto;
	padding: 0;
	overflow-wrap: break-word;
	margin: 0 auto;
	width: 100%;
}

#tab-ui > div {
	color: #000;
}

#tab-ui ul {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	list-style: none;
	margin: 0 auto;
	padding-inline-start: 0;
}

#tab-ui li {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 1 25%;
	text-align: center;
	padding: 0.5em 1em;
	user-select: none;
	cursor: pointer;
	background-color: #32373f;
	border-width: 1px;
	border-color: #eee;
	border-style: solid;
}

.hidden {
	display: none;
}

li.active {
	background-color: #6863a3;
}

#tab-contents {
	position: relative;
	margin-bottom: 15px;
	padding: 15px;
	background-color: #ddd;
}

button,
.deselect {
	/* position: absolute; */
	padding: 5px 10px;
	/* top: 0;
	right: -5px; */
	background-color: #5b88c0;
	color: #fff;
	border-radius: 7px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 30%), inset -1px -1px 5px rgb(0 0 0 / 30%);
	border: none;
	transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

button {
	position: relative;
	top: 0;
	right: 0;
}

button:hover,
.deselect:hover {
	transform: scale(1.05);
	box-shadow: 2px 2px 8px rgb(0 0 0 / 80%), inset -1px -1px 5px rgb(0 0 0 / 10%);
}

#tab-ui li.active {
	background-color: green;
}

.user-account-info {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 60px;
	right: 0;
	left: 0;
	text-align: center;
	background-color: black;
	border: 1px #777 solid;
	box-shadow: -1px 1px 4px 1px rgb(0 0 0 / 50%);
	z-index: 1;
}

.user-account-info > * {
	padding: 5px 15px;
}

.user-account-info div {
	background-color: #fff;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
}

.user-buttons-flex {
	display: flex;
	flex-flow: column;
}

.user-buttons-flex button {
	position: static;
	margin: 10px auto 0;
}

.checkbox-button label,
.checkbox-button input {
	user-select: none;
	cursor: pointer;
	align-self: center;
}

@media (min-width: 768px) {
	.user-account-info {
		position: absolute;
		top: 0;
		bottom: unset;
		flex-flow: column nowrap;
		left: unset;
		align-items: unset;
	}

	.user-list-container {
		flex: 1 0 auto;
		margin: 0 5px 15px 15px;
	}

	.user-list .data-row {
		border: unset;
	}

	.user-list .data-row td {
		padding: 0;
	}

	#tab-ui {
		padding: 0 15px;
	}

	#tab-ui li {
		flex: 1 1 auto;
	}

	/* .deselect {
		top: 15px;
		right: 15px;
	} */
}
</style>
