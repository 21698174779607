<template>
	<table class="audit-list">
		<tr class="sticky">
			<th>Action Id</th>
			<th>Operator Initials</th>
			<th>Date</th>
			<th>Action</th>
			<th>Details/Notes</th>
		</tr>
		<tr v-for="(item, index) in auditList" :key="index">
			<td>{{ item.id }}</td>
			<td :class="item.operatorInitials ? 'operator-id' : 'non-operator'">
				<span :style="getUserIconColors(item?.operatorUserId)">{{
					item?.operatorInitials ? item.operatorInitials : "Non-operator event"
				}}</span>
			</td>
			<td>{{ item.date }}</td>
			<td>{{ item.action }}</td>
			<td class="details">{{ item.details }}</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: "UserAuditLogsTable",
	inheritAttrs: false,
	props: {
		auditList: Array,
	},
	data() {
		return {};
	},
	methods: {
		getUserIconColors(userId) {
			let style = `background-color: #${this.generateUserIdColor(userId).background}; color: #${this.generateUserIdColor(userId).color}`;
			return style;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.audit-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.audit-list th,
.audit-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.details {
	overflow-wrap: anywhere;
}

.non-operator {
	color: #004aa7;
}

.audit-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
