<template>
	<div class="delete-text">
		<div v-if="serverStatus.serverBusy" id="delete-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ serverStatus.busyText }}</span>
		</div>
		<div class="delete-container">
			<fieldset>
				<legend>Delete Safety Check</legend>
				<h3 class="delete-h3">{{ safetyCheckData.displayName }}</h3>
				<p class="yellow-text warn-font">Are you sure you want to delete this user account? This action cannot be undone.</p>
				<table>
					<tr>
						<th class="header-row">User Display Name</th>
						<td class="data-row">{{ safetyCheckData.displayName }}</td>
					</tr>
					<tr>
						<th class="header-row">User Phone Number</th>
						<td class="data-row">{{ safetyCheckData.phoneNumber }}</td>
					</tr>
					<tr>
						<th class="header-row">User Id</th>
						<td class="data-row">{{ safetyCheckData.userId }}</td>
					</tr>
					<tr>
						<th class="header-row">Email Address</th>
						<td class="data-row">{{ safetyCheckData.emailAddress }}</td>
					</tr>
					<tr>
						<th class="header-row">Account Cash Balanced</th>
						<td class="data-row">{{ systemCurrencyTool.formatFromAU(safetyCheckData.accountValueAU, minorWholeOrFull) }}</td>
					</tr>
					<tr>
						<th class="header-row">Last Login Date</th>
						<td class="data-row">{{ formatDateString(safetyCheckData.lastLoginDate) }}</td>
					</tr>
					<tr>
						<th class="header-row">Last Cash Action Date</th>
						<td class="data-row">{{ formatDateString(safetyCheckData.lastCashActionDate) }}</td>
					</tr>
					<tr v-if="safetyCheckData.lastPlayDate">
						<th class="header-row">Last Play Date</th>
						<td class="data-row">{{ formatDateString(safetyCheckData.lastPlayDate) }}</td>
					</tr>
					<tr>
						<th class="header-row">Audit Log Count</th>
						<td class="data-row">{{ safetyCheckData.auditLogCount }}</td>
					</tr>
				</table>
				<div class="warning-box" v-if="showWarningBox">
					<div v-if="safetyCheckData.hasNonPlayerPermissions" class="warn-font help-cursor" :title="helpMessage">
						User Has Non Player Permissions
					</div>
					<div v-if="safetyCheckData.unresolvedMruTransCount" class="red-text warn-font help-cursor" :title="helpMessage">
						{{ `User Has ${safetyCheckData.unresolvedMruTransCount} Unresolved MRU Tranactions` }}
					</div>
					<div v-if="safetyCheckData.unresolvedWebTranCount" class="red-text warn-font help-cursor" :title="helpMessage">
						{{ `User Has ${safetyCheckData.unresolvedWebTranCount} Unresolved Web Tranactions` }}
					</div>
					<div v-if="safetyCheckData.hasOpenCashierBank" class="red-text warn-font help-cursor" :title="helpMessage">
						User Has Open Cashier Bank
					</div>
				</div>
				<div class="flex">
					<button class="btn red-bg" @click="showDeleteDialog()">Delete This User</button>
					<button class="btn" @click="closeModal()">Cancel</button>
				</div>
			</fieldset>
		</div>
	</div>
	<dialog id="delete-dialog">
		<div>
			<p id="dialog-text">
				{{`Are you sure you want to delete ${this.selectedUser.displayName}?` }}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="sendDeleteUserMessage()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeDeleteModal()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
export default {
	name: "DeleteCasinoModal",
	inheritAttrs: false,
	props: {
		adminState: Object,
		selectedUser: Object,
		safetyCheckData: Object,
		serverStatus: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			minorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			showWarningBox:
				this.safetyCheckData.hasNonPlayerPermissions ||
				this.safetyCheckData.unresolvedMruTransCount ||
				this.safetyCheckData.unresolvedWebTranCount ||
				this.safetyCheckData.hasOpenCashierBank,
			helpMessage: "This is just a warning. This user can still be deleted.",
			inputDialog: null,
		};
	},
	mounted() {
		this.inputDialog = document.getElementById("delete-dialog");
	},
	methods: {
		formatDateString(dateString) {
			return new Date(dateString).toLocaleTimeString([], this.dateOptions);
		},
		closeModal() {
			this.eventBus.emit("closeDeleteUserModal");
		},
		sendDeleteUserMessage() {
			this.eventBus.emit("executeDeleteUser");
			this.closeDeleteModal();
		},

		showDeleteDialog() {
			this.inputDialog.showModal();
		},

		closeDeleteModal() {
			this.inputDialog.close();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.delete-text {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden auto;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(5px);
	z-index: 100;
}

.delete-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 80%;
	margin: auto;
	padding: 30px;
	border-radius: 12px;
	text-align: center;
}

.delete-container fieldset {
	display: flex;
	flex-flow: column;
	padding: 30px;
}

.delete-container p,
.delete-container table {
	text-align: left;
}

.delete-container button {
	margin: 5px auto;
}
tr * {
	border: 1px #fff solid;
	padding: 5px 15px;
}

th,
.header-row {
	text-transform: capitalize;
}

.data-row {
	text-align: right;
}

p.yellow-text,
.red-text {
	text-align: center;
}

.warn-font {
	font-weight: bold;
	font-size: 1.25em;
}

.warning-box {
	margin: 15px auto;
	border: 1px #fff solid;
	padding: 15px;
}

.flex {
	display: flex;
	flex-direction: row;
}

.delete-h3 {
	margin: 0;
}

.help-cursor {
	cursor: help;
}

#delete-dialog {
	margin: auto 15px;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	justify-content: center;

	.btn {
		min-width: 6em;
		min-height: 2.5em;
	}
}

#delete-dialog {
		margin: auto;
}

#dialog-text {
	text-align: center;
}

@media (min-width: 768px) {
	.delete-text {
		padding: 130px 0 100px;
	}

	.delete-container {
		width: 50%;
	}
}
</style>
