<template>
	<div id="locked-accounts">
		<h3>User Lock Status</h3>
		<div id="unlock-container">
			<p :class="selectedUser?.lockStatus !== 'NotLocked' ? 'locked' : ''">{{ camelToTitleCase(selectedUser?.lockStatus) }}</p>
			<button v-if="selectedUser?.lockStatus === 'NotLocked'" class="btn red-bg" @click="updateUserLockStatus('LockedByAdmin')">
				Lock User Account
			</button>
			<button v-else @click="updateUserLockStatus('NotLocked')" class="btn">Unlock User Account</button>
		</div>
		<div id="audit-note">
			<label for="auditNote">User Lock Notes (optional)</label>
			<em class="audit-notes">Notes to be added to audit log when locking or unlocking.</em>
			<textarea type="text" name="auditNote" placeholder="User Lock Notes" title="User Lock Notes" v-model="auditNote"></textarea>
		</div>
	</div>
</template>

<script>
// import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "UserAccountLockManager",
	props: {
		adminState: Object,
		selectedUser: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			auditNote: "",
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		async updateUserLockStatus(newStatus) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			this.busyText = "";
			this.serverBusy = true;
			this.status.ok = true;

			let body = {
				userId: this.selectedUser.id,
				auditNote: this.auditNote,
				newStatus: newStatus,
			};

			let requestUrl = new URL("/api/v1/user/lock/", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				let thisUser = this.selectedUser;
				thisUser.lockStatus = dataJson.lockStatus;
				this.eventBus.emit("updateSelectedUser", thisUser);

				this.auditNote = "";

				this.status.message = "User account lock status updated";
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #unlock-container {
	display: flex;
	justify-content: center;
	align-items: center;
} */

/* #unlock-container p {
	padding: 5px 10px;
	border-radius: 8px;
} */

#audit-note,
#unlock-container {
	display: flex;
	flex-direction: column;
}

#unlock-container {
    align-items: center;
}

.audit-notes {
	margin: 0 10px;
}

.locked {
	font-weight: bold;
	color: #f00;
}
</style>
