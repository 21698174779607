<template>
	<div id="play-details">
		<h2>Play #{{ playId }} Details</h2>
		<button class="close-button btn" @click="closePlay()">Close Play</button>
		<CardSpotDetails v-if="playDetails?.id" :playDetails="playDetails" :systemCurrencyTool="systemCurrencyTool" />
		<h3 class="reels-h3" v-if="playDetails?.reelsPlayDetails?.spins?.length > 0">Reels Play Details</h3>
		<div v-for="(spin, index) in playDetails?.reelsPlayDetails?.spins" :key="index">
			<ReelsDetails
				v-if="playDetails?.id && !isBonus(spin)"
				:isWaysGame="isWaysGame(spin)"
				:playDetails="playDetails"
				:spin="spin"
				:spinIndex="index"
				:lastIndex="playDetails.reelsPlayDetails.spins.length === index + 1"
				:systemCurrencyTool="systemCurrencyTool"
			/>
			<BonusDetails
				v-if="playDetails?.id && isBonus(spin)"
				:isWaysGame="isWaysGame(spin)"
				:playDetails="playDetails"
				:spin="spin"
				:spinIndex="index"
				:lastIndex="playDetails.reelsPlayDetails.spins.length === index + 1"
				:systemCurrencyTool="systemCurrencyTool"
			/>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import CardSpotDetails from "@/components/CardSpotDetails";
import ReelsDetails from "@/components/ReelsDetails";
import BonusDetails from "@/components/BonusDetails";

export default {
	name: "PlayActivityDetail",
	inheritAttrs: false,
	components: {
		CardSpotDetails,
		ReelsDetails,
		BonusDetails,
	},
	props: {
		adminState: Object,
		playId: Number,
		playList: Array,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			body: document.querySelector("body"),
			playDetails: {},
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
		};
	},
	created() {
		this.getPlayDetails();
		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closePlay();
		};
	},
	methods: {
		isBonus(spin) {
			return spin.endingState.symbolGrid.trim().length === 0;
		},
		isWaysGame(spin) {
			let waysTest = (item) => item.numberOfWays > 0;
			return spin.pays.some(waysTest);
		},
		closePlay() {
			this.eventBus.emit("closePlayDetail");
		},
		getPlayDetails() {
			this.playDetails = this.playList.filter((play) => this.playId === play.id)[0] || {};
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#play-loading {
	position: absolute;
	display: grid;
	align-content: center;
	width: 100%;
	height: calc(90% + 0.5em);
}

#play-details {
	position: fixed;
	top: 80px;
	right: 0;
	bottom: 0;
	height: calc(100vh - 140px);
	width: 100%;
	padding: 0;
	overflow: hidden auto;
	background-color: #ddd;
	z-index: 1;
}

.play-details {
	padding: 30px;
}

#play-details h2 {
	position: sticky;
	top: 0;
	margin: 0;
	padding: 15px;
	background-color: #bbb;
	color: #000;
	border-bottom: 1px #000 solid;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 40%);
}

.close-button {
	position: fixed;
	width: 7em;
	top: 140px;
	right: 0;
	left: unset;
}

.reels-h3 {
	margin-bottom: 3em;
}

.play-detail-container .grid-container {
	user-select: none;
}

@media (min-width: 768px) {
	#play-details {
		height: calc(100vh - 160px);
		width: 80%;
		top: 98px;
	}

	.close-button {
		top: 110px;
		right: 30px;
	}
}
</style>
