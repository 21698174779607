<template>
	<div class="bonus-details">
		<div class="bonus-detail-container">
			<div class="reels-play-details-container">
				<table class="pay-table">
					<thead>
						<tr>
							<th class="mode" colspan="10">Mode: {{ spin.endingState.mode }} ({{ spinIndex }})</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								Bonus:
								<span>
									{{ systemCurrencyTool.formatFromAU(totalBonusAU(spin.bonusPrize), wholeOrFull) }}
									<span v-for="(prog, index) in playDetails.progressiveClaims" :key="index"> + tier{{ prog.tier }}</span>
								</span>
							</td>
							<td class="flex-col" v-if="registersArray(spin.bonusPrize.registers).length > 0">
								<strong>Registers:</strong>
								<span v-for="(bonusRegister, index) in registersArray(spin.bonusPrize.registers)" :key="index">{{
									bonusRegister
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<table class="pay-table" v-if="spin.pays.length > 0">
					<tbody>
						<tr v-if="isWaysGame">
							<td class="ways-game">Ways Pay</td>
						</tr>
						<tr>
							<td>
								Bonus:
								<span>
									{{ systemCurrencyTool.formatFromAU(totalBonusAU(spin.bonusPrize), wholeOrFull) }}
									<span v-for="(prog, index) in playDetails.progressiveClaims" :key="index"> + tier{{ prog.tier }}</span>
								</span>
							</td>
							<td class="flex-col" v-if="registersArray(spin.bonusPrize.registers).length > 0">
								<strong>Registers:</strong>
								<span v-for="(bonusRegister, index) in registersArray(spin.bonusPrize.registers)" :key="index">{{
									bonusRegister
								}}</span>
							</td>
						</tr>
						<tr class="spin-container" v-for="(pay, index) in spin.pays" :key="index">
							<td v-if="isWaysGame && pay.numberOfWays !== 0">{{ pay.numberOfWays }} Ways</td>
							<td v-else>{{ pay.lineNumber ? `Line: ${pay.lineNumber}` : "Scatter" }}</td>
							<td>{{ pay.lineNumber ? pay.symbols : `${pay.symbols} * ${pay.matchCount}` }}</td>
							<td>
								Pay: {{ systemCurrencyTool.formatFromAU(payTableAU(playDetails, pay.prize), wholeOrFull) }}
								<span v-if="pay.prize.progressiveTierBits !== 0">
									<span v-for="(prog, index) in playDetails.progressiveClaims" :key="index"> + tier{{ prog.tier }}</span>
								</span>
							</td>
							<td class="flex-col" v-if="registersArray(pay.prize.registers).length > 0">
								<strong>Registers:</strong>
								<span v-for="(prizeRegister, index) in registersArray(pay.prize.registers)" :key="index">{{ prizeRegister }}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<table class="end-state-table">
					<thead>
						<tr>
							<th colspan="2">End State</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>Accumulated Paytable Win</th>
							<td class="cash-display">{{ systemCurrencyTool.formatFromAU(accumulatedPayTableAU(playDetails, spin.endingState), wholeOrFull) }}</td>
						</tr>
						<tr>
							<th>Accumulated Progressive Win</th>
							<td class="cash-display">{{ systemCurrencyTool.formatFromAU(spin.endingState.accumulatedProgressiveClaimsAU, wholeOrFull) }}</td>
						</tr>
						<tr v-if="registersArray(spin.endingState.registers).length > 0">
							<th>Registers</th>
							<td>
								<span v-for="(endRegister, index) in registersArray(spin.endingState.registers)" :key="index">{{ endRegister }}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<hr class="spin-hr" v-if="!lastIndex" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BonusDetails",
	inheritAttrs: false,
	props: {
		playDetails: Object,
		isWaysGame: Boolean,
		spin: Object,
		spinIndex: Number,
		lastIndex: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			wholeOrFull: this.systemCurrencyTool.displayType.wholeOrFull,
		};
	},
	methods: {
		totalBonusAU(bonusState) {
			let numberOfLines = this.playDetails.reelsPlayDetails.numberOfLines;
			let baseBet = this.playDetails.reelsPlayDetails.baseBet;
			let denomAU = this.playDetails.reelsPlayDetails.denominationAU;

			// if numberOfLines is 0, we assume we're playing a Ways game.
			let lineMult = numberOfLines > 0 ? numberOfLines : 1;

			let baseBetPart = baseBet * bonusState.baseBetMultiple;
			let totalBetPart = lineMult * baseBet * bonusState.totalBetMultiple;
			return denomAU * (baseBetPart + totalBetPart + bonusState.denomMultiple);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
	text-align: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.spin-container {
	margin-bottom: 15px;
}

.play-detail-container {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: center;
}

.play-detail-container > div,
.reels-ball-draw > * {
	margin: 15px;
	align-self: center;
}

.pay-table {
	width: 90%;
	margin: 15px auto 30px;
	background-color: #fff;
	box-shadow: 2px 3px 6px rgb(0 0 0 / 40%);
}

.end-state-table {
	width: 90%;
	margin: 15px auto 4em;
	background-color: #fff;
	box-shadow: 2px 3px 6px rgb(0 0 0 / 40%);
}

.end-state-table thead {
	background-color: #000;
	color: #fff;
}

.pay-table td,
.end-state-table td,
.end-state-table th {
	border: 1px #aaa solid;
	padding: 5px 10px;
}

.end-state-table td {
	display: flex;
	flex-flow: column;
}

.end-state-table tbody th {
	text-align: left;
}

.ways-game {
	background-color: #000;
	color: #fff;
}

.registers {
	display: flex;
	flex-direction: column;
}

.registers span,
.indent > span {
	margin-left: 15px;
}

.mode {
	padding: 10px;
	text-align: center;
}

@media (min-width: 768px) {
	.pay-table {
		width: unset;
	}

	.end-state-table {
		width: unset;
	}
}
</style>
