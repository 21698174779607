<template>
	<table v-for="(item, index) in playList" :key="index" class="play-list" @click="selectPlayId(item.id)">
		<tr>
			<th>Game Title</th>
			<td>{{ item.gameTitle }}</td>
		</tr>
		<tr>
			<th>Play Id</th>
			<td class="play-id">{{ item.id }}</td>
		</tr>
		<tr>
			<th>Wager</th>
			<td class="au-block">
				<span class="reg-au">Cash: {{ systemCurrencyTool.formatFromAU(item.wager.regularAU, full) }}</span>
				<span class="promo-au">Promo: {{ systemCurrencyTool.formatFromAU(item.wager.promoAU, full) }}</span>
			</td>
		</tr>
		<tr>
			<th>Resolved</th>
			<td>{{ new Date(item.resolvedTime).toLocaleTimeString([], shortDateOptions) }}</td>
		</tr>
		<tr>
			<th>Total Win</th>
			<td :class="playRowClass(item)" :title="item.totalWinAU > 0 ? `Play won: ${systemCurrencyTool.formatFromAU(item.totalWinAU, full)}` : ''">
				{{ systemCurrencyTool.formatFromAU(item.totalWinAU, full) }}
				<span
					v-if="item.fundsLock && !item.fundsLock.claimDate"
					class="locked-win lock-icon"
					title="Funds locked. See Cashier to release lock."
				></span>
				<span
					v-if="item.fundsLock && item.fundsLock.claimDate"
					class="unlocked-win"
					:title="`This funds lock was released on ${new Date(item.fundsLock.claimDate).toLocaleTimeString([], dateOptions)}`"
					>rel</span
				>
			</td>
		</tr>
		<tr>
			<th>Ending Balance</th>
			<td class="au-block">
				<span class="reg-au">Cash: {{ systemCurrencyTool.formatFromAU(item.endingCreditBalance.regularAU, full) }}</span>
				<span class="promo-au">Promo: {{ systemCurrencyTool.formatFromAU(item.endingCreditBalance.promoAU, full) }}</span>
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: "PlayActivityTable",
	props: {
		playList: Array,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	methods: {
		selectPlayId(playId) {
			this.eventBus.emit("selectPlayId", playId);
		},
		playRowClass(play) {
			let classList = [];
			if (play.totalWinAU > 0) classList.push("play-win");
			if (play.fundsLock) classList.push("locked-threshold");
			return classList.join(" ");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.play-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
	margin-bottom: 2em;
}

.play-list th,
.play-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.play-list th {
	width: 6em;
	color: #fff;
	background-color: #000;
}

.details {
	overflow-wrap: anywhere;
}

.play-win {
	position: relative;
	font-weight: bold;
	background-color: #fff;
	color: green;
}

.play-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.play-row {
	cursor: pointer;
	transition: background-color 0.5s ease, color 0.5s ease;
}

tr.play-row:hover {
	background-color: #5b88bf;
	color: white;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.reels-play-details-container {
		padding-bottom: 100px;
	}
}

.au-block {
	display: flex;
	flex-flow: column nowrap;
}

.locked-win,
.unlocked-win {
	position: absolute;
	padding: 5px 6px;
	background-color: #f00;
	color: #fff;
	top: 0.5625em;
	right: 0.5625em;
	user-select: none;
	font-weight: normal;
	text-transform: uppercase;
	border: 1px #000 solid;
	border-radius: 100%;
	cursor: help;
}

.unlocked-win {
	background-color: #008000;
	line-height: 0.9em;
	top: 2px;
	border-radius: 8px;
}

.lock-icon::after {
	content: "";
	position: absolute;
	width: 24px;
	height: 24px;
	left: -5px;
	top: -7px;
	background: red
		url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20style%3D%22fill%3A%23FFFFFF%3B%22%20d%3D%22M12%2C6V5c0-2.762-2.238-5-5-5C4.239%2C0%2C2%2C2.238%2C2%2C5v1H1v8h12V6H12z%20M7.5%2C9.848V12h-1V9.848%20C6.207%2C9.673%2C6%2C9.366%2C6%2C9c0-0.553%2C0.448-1%2C1-1s1%2C0.447%2C1%2C1C8%2C9.366%2C7.793%2C9.673%2C7.5%2C9.848z%20M10%2C6H4V5c0-1.657%2C1.343-3%2C3-3%20s3%2C1.343%2C3%2C3V6z%22%2F%3E%3C%2Fsvg%3E")
		no-repeat;
	background-size: 13px;
	background-position: 4px 4px;
	border-radius: 100%;
	border: 1px solid #000;
	box-sizing: border-box;
}

td.locked-threshold {
	padding-right: 50px;
}
</style>
