<template>
	<div id="user-actions" v-if="selectedUser?.phoneNumber">
		<h2>{{ selectedUser?.displayName }} Account Actions</h2>
		<h3 v-if="selectedUser?.lockStatus !== 'NotLocked'" class="locked">
			Account is locked. <button class="btn" @click="scrollToUnlockComponent()">Change Lock Status</button>
		</h3>
		<Permissions
			v-if="adminState?.isSysadmin || adminState?.isSiteAdmin || adminState?.isManager"
			:adminState="adminState"
			:selectedUser="selectedUser"
			:casinoList="casinoList"
		/>
		<UserPasswordReset
			v-if="adminState?.isSysadmin || adminState?.isSiteAdmin || adminState?.isHelpDesk || adminState?.isManager"
			:adminState="adminState"
			:selectedUser="selectedUser"
		/>
		<UserAccountLockManager v-if="adminState?.isSysadmin" :adminState="adminState" :selectedUser="selectedUser" />
		<DeleteUser v-if="adminState?.isSysadmin" :adminState="adminState" :selectedUser="selectedUser" :systemCurrencyTool="systemCurrencyTool" />
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
</template>

<script>
import UserAccountLockManager from "@/components/UserAccountLockManager";
import UserPasswordReset from "@/components/UserPasswordReset";
import Permissions from "@/components/Permissions";
import DeleteUser from "@/components/DeleteUser";

export default {
	name: "UserActions",
	components: {
		UserAccountLockManager,
		UserPasswordReset,
		Permissions,
		DeleteUser,
	},
	inheritAttrs: false,
	props: {
		adminState: Object,
		casinoList: Array,
		selectedUser: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			unlock: null,
		};
	},
	methods: {
		scrollToUnlockComponent() {
			let unlock = document.getElementById("locked-accounts");
			unlock.scrollIntoView({ behavior: "smooth" });
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#user-actions > div {
	margin-bottom: 30px;
	padding: 0 15px 15px;
	border: 1px #787878 solid;
}

#user-actions > div:last-child {
	margin-bottom: auto;
}

.locked {
	background-color: #f00;
	color: #fff;
	font-weight: bold;
	padding: 5px 10px;
	width: 90%;
	margin: 20px auto;
	border-radius: 8px;
}

@media (min-width: 768px) {
	.locked {
		width: 50%;
	}
}
</style>
