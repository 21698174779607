<template>
	<div class="user-permissions-container">
		<table class="global-permissions">
			<tr>
				<th>Global Permissions</th>
				<td>{{ selectedUser.permissions?.globalPermissions }}</td>
			</tr>
		</table>
		<div v-if="adminState?.isSysadmin">
			<button class="add btn" :title="globalButtonText" @click="openPermisionEditorPermissions()">{{ globalButtonText }}</button>
			<div v-if="openPermisionEditor">
				<h3>Global Permissions</h3>
				<div class="add-site-permissions">
					<div class="permissions-check">
						<div>
							<label for="playerPermissions">Player</label>
							<input type="checkbox" id="playerPermissions" v-model="playerPermissions" value="Player" />
						</div>
						<div>
							<label for="sysadminPermissions">Sysadmin</label>
							<input type="checkbox" id="sysadminPermissions" v-model="sysadminPermissions" value="Sysadmin" />
						</div>
						<div>
							<label for="helpDeskPermissions">HelpDesk</label>
							<input type="checkbox" id="helpDeskPermissions" v-model="helpDeskPermissions" value="HelpDesk" />
						</div>
					</div>
					<button class="submit btn" title="Submit" @click="submitPermissionsChange()">Submit</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";

export default {
	name: "GlobalPermissions",
	props: {
		adminState: Object,
		selectedUser: Object,
		serverBusy: Boolean,
	},
	data() {
		return {
			openPermisionEditor: false,
			globalButtonText: "Edit Global Permissions",
			playerPermissions: null,
			sysadminPermissions: null,
			helpDeskPermissions: null,
			selectedCasinoId: null,
		};
	},
	watch: {
		openPermisionEditor() {
			this.globalButtonText = this.openPermisionEditor ? "Close Global Editor" : "Edit Global Permissions";
			this.populateGlobalOptions();
		},
		serverBusy() {
			this.openPermisionEditor = this.openPermisionEditor && !this.serverBusy ? false : true;
		},
		selectedUser: {
			handler() {
				if (this.selectedUser?.phoneNumber && !this.selectedUser?.permissions) {
					this.populateGlobalOptions();
				}
			},
			deep: true,
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("deselectUser", () => {
			thisInstance.openPermisionEditor = false;
			thisInstance.sitePermissionsArray = [];
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("deselectUser");
		});
	},
	mounted() {
		this.populateGlobalOptions();
	},
	methods: {
		openPermisionEditorPermissions() {
			this.openPermisionEditor = this.openPermisionEditor ? false : true;
		},
		populateGlobalOptions() {
			let globalPermissions = this.selectedUser?.permissions?.globalPermissions;
			this.playerPermissions = globalPermissions?.includes("Player");
			this.sysadminPermissions = globalPermissions?.includes("Sysadmin");
			this.helpDeskPermissions = globalPermissions?.includes("HelpDesk");
		},
		async submitPermissionsChange() {
			let newPermissions = {};
			let permissionsArray = [];

			if (this.playerPermissions) permissionsArray.push("Player");
			if (this.sysadminPermissions) permissionsArray.push("Sysadmin");
			if (this.helpDeskPermissions) permissionsArray.push("HelpDesk");
			newPermissions.globalPermissions = permissionsArray.length > 0 ? permissionsArray.join(", ") : "None";

			let payload = {
				newPermissions: newPermissions,
				sitePermissions: false,
			};
			this.eventBus.emit("submitPermissionsChange", payload);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.global-permissions {
	width: 100%;
	margin: 30px auto 15px;
	text-align: left;
	border-collapse: collapse;
}

th,
td {
	border: 1px #000 solid;
	padding: 5px 10px;
	width: 50%;
}

tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.add-site-permissions {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	user-select: none;
}

.permissions-check {
	display: flex;
	flex-flow: row wrap;
}

.permissions-check > div {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.permissions-check label,
.permissions-check input {
	cursor: pointer;
}

.checkbox-button input {
	align-self: center;
}
</style>
