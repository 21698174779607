<template>
	<div id="user-audit" v-if="selectedUser?.phoneNumber">
		<h2>{{ selectedUser?.displayName }} Audit Logs</h2>
		<div class="user-audit-container">
			<div v-if="!!serverBusy" id="audit-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<div id="paging">
				<button id="refresh" title="Refresh audit logs" class="btn" @click="getUserAuditLogs()">Refresh</button>
				<button v-if="selectedUser?.phoneNumber" @click="userNoteModal()" title="Add User Note" class="add-note">Add User Note</button>
				<label for="limitOptions">Limit List</label>
				<select title="Set Limit" v-model="limit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
				<button :disabled="serverBusy" class="prev-button" type="button" title="Previous" @click="previousPage()">Previous</button>
				<button :disabled="serverBusy" class="next-button" type="button" title="Next" @click="nextPage()">Next</button>
				<span :currentPage="currentPage">page {{ currentPage }}</span>
			</div>
			<UserAuditLogsTableMobile v-if="auditList.length > 0 && isMobile" :auditList="auditList" />
			<UserAuditLogsTable v-if="auditList.length > 0 && !isMobile" :auditList="auditList" />
		</div>
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
	<transition name="fade">
		<component v-bind:is="currentModal" :adminState="adminState" :selectedUser="selectedUser" />
	</transition>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import AddUserNote from "@/components/AddUserNote";
import UserAuditLogsTableMobile from "@/components/UserAuditLogsTableMobile";
import UserAuditLogsTable from "@/components/UserAuditLogsTable";

export default {
	name: "UserAuditLogs",
	inheritAttrs: false,
	props: {
		adminState: Object,
		selectedUser: Object,
		isMobile: Boolean,
	},
	components: {
		AddUserNote,
		UserAuditLogsTableMobile,
		UserAuditLogsTable,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			currentModal: null,
			offset: 0,
			currentPage: 1,
			isLastPage: false,
			limit: this.limit,
			auditList: [],
			addNote: false,
		};
	},
	watch: {
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.limit = Number(this.limit);
			this.getUserAuditLogs();
		},
		selectedUser: {
			handler() {
				if (!this.selectedUser?.id) this.auditList = [];
				if (this.selectedUser?.id) this.getUserAuditLogs();
			},
			deep: true,
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("closeAddNoteModal", () => {
			thisInstance.currentModal = null;
		});
		this.eventBus.on("deselectUser", () => {
			thisInstance.auditList = [];
			thisInstance.limit;
		});
		this.eventBus.on("updateAuditLogs", () => {
			thisInstance.getUserAuditLogs();
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("closeAddNoteModal");
			thisInstance.eventBus.off("deselectUser");
			thisInstance.eventBus.off("updateAuditLogs");
		});
	},
	activated() {
		if (this.selectedUser?.phoneNumber) this.getUserAuditLogs();
	},
	methods: {
		userNoteModal() {
			this.currentModal = "AddUserNote";
		},
		async getUserAuditLogs() {
			// Check component's isDeactivated property and if server is not already refreshing access token. Return if either true.
			if (this.$.isDeactivated || this.refreshTokenAwait) return;
			this.busyText = `Loading page ${this.currentPage} Audit Logs for ${this.selectedUser?.displayName}`;
			this.serverBusy = true;

			// get one more item than requested to see if a second page exists
			let currentLimit = this.limit + 1;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/user/${this.selectedUser.id}/audit`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.offset) params.set("offset", this.offset);
			if (this.limit) params.set("limit", currentLimit);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let thisInstance = this;
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				if (Array.isArray(dataJson)) {
					dataJson.forEach((item) => {
						item.date = new Date(item.date).toLocaleTimeString([], this.dateOptions);
					});
					this.isLastPage = dataJson?.length <= this.limit;
					// remove extra item if a next page exists
					if (!this.isLastPage) dataJson.pop();
				} else {
					this.status.message = `Server returned code ${dataJson.status || dataJson} but did not return a list`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.auditList = dataJson;

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.getUserAuditLogs();
		},
		nextPage() {
			if (this.auditList.length < this.limit) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.getUserAuditLogs();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.audit-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.audit-list th,
.audit-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.details {
	overflow-wrap: anywhere;
}

.non-operator {
	color: #004aa7;
}

.audit-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.add-note {
	padding: 5px 10px;
	background-color: #5b88c0;
	color: #fff;
	border-radius: 7px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 30%), inset -1px -1px 5px rgb(0 0 0 / 30%);
	border: none;
	transition: box-shadow 0.2 ease-in-out, transform 0.2s ease-in-out;
}

.add-note:hover {
	transform: scale(1.05);
	box-shadow: 2px 2px 8px rgb(0 0 0 / 80%), inset -1px -1px 5px rgb(0 0 0 / 10%);
}
</style>