<template>
	<div id="filter-container">
		<div class="filter-fieldset-container">
			<fieldset>
				<legend>Filter List</legend>
				<div class="filter-options">
					<div>
						<label for="gameTitle">Game Title</label>
						<select id="gameTitle" title="Game Title" v-model="gameTitle">
							<option v-for="(title, index) in allGameTitles" :key="index" :value="title">{{ title }}</option>
						</select>
						<span class="clear-item" title="Clear this filter" @click="gameTitle = null">x</span>
					</div>
					<div>
						<label for="minWinAU">Min Win AU</label>
						<input id="minWinAU" type="number" placeholder="Min Win AU" title="Min Win AU" v-model="minWinAU" />
						<span class="clear-item" title="Clear this filter" @click="minWinAU = null">x</span>
					</div>
					<div>
						<label for="maxWinAU">Max Win AU</label>
						<input id="maxWinAU" type="number" placeholder="Max Win AU" title="Max Win AU" v-model="maxWinAU" />
						<span class="clear-item" title="Clear this filter" @click="maxWinAU = null">x</span>
					</div>
					<div>
						<label for="startDate">Start Date</label>
						<input
							id="startDate"
							:class="errorState ? 'date-error' : ''"
							:min="startMin"
							:max="startMax"
							type="datetime-local"
							placeholder="Start Date"
							title="Start Date"
							v-model="startDate"
						/>
						<span class="clear-item" title="Clear this filter" @click="startDate = null">x</span>
					</div>
					<div>
						<label for="endDate">End Date</label>
						<input
							id="endDate"
							:class="errorState ? 'date-error' : ''"
							:min="endMin"
							:max="endMax"
							type="datetime-local"
							placeholder="End Date"
							title="End Date"
							v-model="endDate"
						/>
						<span class="clear-item" title="Clear this filter" @click="endDate = null">x</span>
					</div>
				</div>
				<button @click="applyFilters()" title="Apply all filters" class="apply-filters btn">Apply Filters</button>
				<button @click="clearFilters()" title="Clear all filters" class="close-modal btn">Clear Filters</button>
				<button @click="closeModal()" title="Close" class="close-modal btn">Close</button>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";

export default {
	name: "FilterList",
	props: {
		allGameTitles: Array,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
			body: document.querySelector("body"),
			filters: {},
			errorState: false,
			gameTitle: null,
			minWinAU: null,
			maxWinAU: null,
			startDate: null,
			endDate: null,
			startMin: null,
			startMax: null,
			endMin: null,
			endMax: null,
		};
	},
	watch: {
		startDate() {
			this.endMin = this.startDate;
		},
		endDate() {
			this.startMax = this.endDate;
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("resetFilterComponent", () => {
			thisInstance.clearFilters();
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("resetFilterComponent");
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
	},
	methods: {
		throwDateTimeError(input) {
			this.status.message = `${input} is not complete. Must have both the date and time or be left unset/empty.`;
			this.status.ok = false;
			this.eventBus.emit("updateStatus", this.status);
		},
		applyFilters() {
			let errState = false;
			this.filters = {
				gameTitle: this.gameTitle,
				minWinAU: this.minWinAU,
				maxWinAU: this.maxWinAU,
				startDate: this.startDate ? this.formatDateTimeISOString(this.startDate) : null,
				endDate: this.endDate ? this.formatDateTimeISOString(this.endDate) : null,
			};

			if (document.getElementById("startDate").validity.badInput) {
				this.throwDateTimeError("Start Date");
				errState = true;
			}

			if (document.getElementById("endDate").validity.badInput) {
				this.throwDateTimeError("End Date");
				errState = true;
			}

			if (errState) return false;

			this.eventBus.emit("applyFilters", this.filters);
		},
		clearFilters() {
			this.filters = {};
			this.errorState = false;
			this.gameTitle = null;
			this.minWinAU = null;
			this.maxWinAU = null;
			this.startDate = null;
			this.endDate = null;
			this.startMin = null;
			this.startMax = null;
			this.endMin = null;
			this.endMax = null;
		},
		closeModal() {
			this.eventBus.emit("closeFiltersModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#filter-container {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(5px);
	z-index: 100;
}

.filter-fieldset-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 90vw;
	margin: auto;
	padding: 15px;
	border-radius: 12px;
	text-align: center;
}

.filter-options {
	display: flex;
	flex-flow: column;
	padding: 0;
}

.filter-options > div {
	display: flex;
	justify-content: flex-end;
}

.filter-options input,
.filter-options select {
	flex: 0 1 15em;
	padding: 0;
}

input,
label,
select,
button {
	margin: 5px;
	align-self: center;
}

.filter-options select {
	box-sizing: content-box;
}

.clear-item {
	align-self: center;
	justify-self: center;
	background: #5a87bf;
	padding: 0px 5px 2px;
	margin: 0;
	border-radius: 100%;
	cursor: pointer;
	font-size: 0.8em;
}

.date-error {
	border: 3px red solid;
}
@media (min-width: 768px) {
	.filter-fieldset-container {
		width: 28em;
		padding: 30px;
	}

	.filter-options {
		padding: 30px;
	}

	.filter-options input,
	.filter-options select {
		flex: 0 0 15em;
		padding: 0;
	}

	input,
	label,
	select,
	button {
		margin: 5px 10px;
	}
}
</style>
