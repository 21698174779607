<template>
	<div class="reels-details">
		<div class="reels-detail-container">
			<div class="reels-play-details-container">
				<div class="symbol-grid">
					<div class="grid-container">
						<div class="grid-item" v-for="(symbol, index) in spin.endingState.symbolGrid" :key="index">
							<img :src="getSymbol(symbol)" :alt="symbol" :title="symbol" />
						</div>
					</div>
				</div>
				<table class="pay-table" v-if="spin.pays.length > 0">
					<thead>
						<tr>
							<th class="mode" colspan="10">Mode: {{ spin.endingState.mode }} ({{ spinIndex }})</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="isWaysGame">
							<td class="ways-game">Ways Pay</td>
						</tr>
						<tr class="spin-container" v-for="(pay, index) in spin.pays" :key="index">
							<td v-if="isWaysGame && pay.numberOfWays !== 0">{{ pay.numberOfWays }} Ways</td>
							<td v-else>{{ pay.lineNumber ? `Line: ${pay.lineNumber}` : "Scatter" }}</td>
							<td class="symbol-match">
								<span v-for="(symbol, index) in pay?.symbols" :key="index">
									<img class="symbol-small" v-if="symbol" :src="getSymbol(symbol)" :alt="symbol" :title="symbol" />
								</span>
								{{ pay.lineNumber ? "" : ` * ${pay.matchCount}` }}
								{{ pay.symbolMultipliers > 1 ? ` mult ${pay.symbolMultipliers}` : "" }}
							</td>
							<td class="cash-display">
								Pay: {{ systemCurrencyTool.formatFromAU(payTableAU(playDetails, pay.prize), systemFull) }}
								<span v-if="pay?.prize?.progressiveTierBits !== 0">
									<span v-for="(prog, index) in playDetails.progressiveClaims" :key="index"> + tier{{ prog.tier }}</span>
								</span>
							</td>
							<td class="flex-col" v-if="registersArray(pay?.prize?.registers).length > 0">
								<strong>Registers:</strong>
								<span v-for="(prizeRegister, index) in registersArray(pay.prize.registers)" :key="index">{{ prizeRegister }}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-else class="no-pays">
					<h3>No pays for this spin ({{ spinIndex }})</h3>
				</div>
				<table class="end-state-table">
					<thead>
						<tr>
							<th colspan="2">End State</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>Accumulated Paytable Win</th>
							<td class="cash-display">{{ systemCurrencyTool.formatFromAU(accumulatedPayTableAU(playDetails, spin.endingState), systemFull) }}</td>
						</tr>
						<tr>
							<th>Accumulated Progressive Win</th>
							<td class="cash-display">{{ systemCurrencyTool.formatFromAU(spin.endingState.accumulatedProgressiveClaimsAU, systemFull) }}</td>
						</tr>
						<tr v-if="registersArray(spin.endingState.registers).length > 0">
							<th>Registers</th>
							<td>
								<span v-for="(endRegister, index) in registersArray(spin.endingState.registers)" :key="index">{{ endRegister }}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<hr class="spin-hr" v-if="!lastIndex" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ReelsDetails",
	inheritAttrs: false,
	props: {
		playDetails: Object,
		isWaysGame: Boolean,
		spin: Object,
		spinIndex: Number,
		lastIndex: Boolean,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			symbolPath: `./symbols/${this.playDetails.gameTitle.replace(/\W/g, "")}/`,
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	methods: {
		// Static Symbols from MGP can be found here https://github.com/Meta-Gaming-Studios/MGP/tree/master/Assets/Recall
		getSymbol(symbol) {
			return `${this.symbolPath}${symbol}.png`;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
	text-align: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.grid-container {
	display: grid;
	justify-content: center;
	justify-items: center;
	align-content: center;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(5, 1fr);
	grid-gap: 5px;
	background-color: #bbb;
	padding: 15px;
	border: 1px #000 solid;
	border-radius: 10px;
	box-shadow: 2px 3px 6px rgb(0 0 0 / 40%);
}

.symbol-grid .grid-container {
	grid-template-rows: repeat(3, 1fr);
	width: 20em;
	margin: 15px auto;
	user-select: none;
}

.reels-play-details-container > div {
	margin-bottom: 4em;
}

.spin-container {
	margin-bottom: 15px;
}

.grid-item {
	display: grid;
	justify-content: center;
	align-content: center;
	font-size: 1.5rem;
	box-sizing: border-box;
	border: 1px solid #000;
	background-color: rgb(255, 255, 255);
	padding: 5px;
	height: 2em;
	width: 2em;
}

.play-detail-container {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: center;
}

.play-detail-container > div,
.reels-ball-draw > * {
	margin: 15px;
	align-self: center;
}

.end-state-table,
.pay-table {
	width: 90%;
	margin: 15px auto 30px;
	background-color: #fff;
	box-shadow: 2px 3px 6px rgb(0 0 0 / 40%);
}

.end-state-table thead,
.pay-table thead {
	background-color: #000;
	color: #fff;
}

.pay-table td,
.end-state-table td,
.end-state-table th {
	border: 1px #aaa solid;
	padding: 5px 10px;
}

.end-state-table td {
	display: flex;
	flex-flow: column;
}

.end-state-table tbody th {
	text-align: left;
}

.ways-game {
	background-color: #000;
	color: #fff;
}

.registers {
	display: flex;
	flex-direction: column;
}

.registers span,
.indent > span {
	margin-left: 15px;
}

.mode {
	padding: 10px;
	text-align: center;
}

.grid-item img {
	width: 42px;
	height: 42px;
	cursor: help;
}

.symbol-small {
	width: 20px;
	height: 20px;
	padding: 0 2px;
	vertical-align: middle;
	cursor: help;
}

@media (min-width: 768px) {
	.pay-table {
		width: unset;
	}

	.end-state-table {
		width: unset;
	}

	.symbol-small {
		width: 32px;
		height: 32px;
	}
}
</style>
