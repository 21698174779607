<template>
	<div id="user-permissions" v-if="selectedUser?.phoneNumber">
		<h3 :class="selectedUser?.permissions?.arePermissionsSuspended ? 'suspened' : ''">
			<span> Permissions for {{ selectedUser?.displayName }}</span>
			<span v-if="selectedUser?.permissions?.arePermissionsSuspended"> are temporarily suspended.</span>
			<span v-if="!adminState?.isSysadmin && selectedUser?.permissions?.arePermissionsSuspended">  Only a SysAdmin can restore them.</span>
		</h3>
		<button v-if="adminState?.isSysadmin && selectedUser?.permissions?.arePermissionsSuspended" class="btn" @click="restorePermissions()">
			Restore Permissions
		</button>
		<div class="user-permissions-container">
			<GlobalPermissions v-if="adminState?.isSysadmin" :adminState="adminState" :selectedUser="selectedUser" :serverBusy="serverBusy" />
			<SitePermissions
				:adminState="adminState"
				:selectedUser="selectedUser"
				:casinoList="casinoList"
				:sitePermissionsArray="sitePermissionsArray"
				:serverBusy="serverBusy"
			/>
		</div>
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import GlobalPermissions from "@/components/GlobalPermissions";
import SitePermissions from "@/components/SitePermissions";

export default {
	name: "Permissions",
	components: {
		SitePermissions,
		GlobalPermissions,
	},
	props: {
		adminState: Object,
		casinoList: Array,
		selectedUser: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			sitePermissionsArray: [],
			selectedCasinoId: null,
		};
	},
	watch: {
		openPermisionEditor() {
			this.globalButtonText = this.openPermisionEditor ? "Close Permissions Editor" : "Edit Permissions";
		},
		selectedUser: {
			handler() {
				if (this.selectedUser?.phoneNumber && !this.selectedUser?.permissions) {
					this.sitePermissionsArray = [];
					this.getUserPermissions();
				}
			},
			deep: true,
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("deselectUser", () => {
			thisInstance.sitePermissionsArray = [];
		});
		this.eventBus.on("submitPermissionsChange", (payload) => {
			thisInstance.submitPermissionsChange(payload);
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("deselectUser");
			thisInstance.eventBus.off("submitPermissionsChange");
		});
	},
	mounted() {
		this.getUserPermissions();
	},
	methods: {
		async restorePermissions() {
			this.busyText = `Restoring permissions for ${this.selectedUser?.displayName}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				UserId: this.selectedUser.id,
			};

			let requestUrl = new URL("/api/v1/user/permissions/restore", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.sitePermissionsArray = this.convertToArrayOfCasinoPermissions(dataJson.sitePermissions);

				this.serverBusy = false;
				this.busyText = "";
				this.selectedCasinoId = null;
				this.addGlobal = this.openPermisionEditor = false;

				let thisUser = this.selectedUser;
				thisUser.permissions = dataJson;
				this.eventBus.emit("updateSelectedUser", thisUser);
				this.status.message = "Permissions Restored";
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		async submitPermissionsChange(payload) {
			let submitSitePermissions = payload.sitePermissions;
			this.selectedCasinoId = this.selectedCasinoId || payload.selectedCasinoId;

			this.busyText = `Saving permissions for ${this.selectedUser?.displayName}`;
			this.serverBusy = true;

			let isThisSiteAdmin = this.adminState?.userPermissions?.sitePermissions?.[this.selectedCasinoId]?.includes("SiteAdmin");
			let isSysAdmin = this.adminState?.userPermissions?.globalPermissions.includes("Sysadmin");
			let isManager = this.adminState?.userPermissions?.sitePermissions?.[this.selectedCasinoId]?.includes("Manager");
			let canEditPermissions = isSysAdmin || isManager || (isThisSiteAdmin && submitSitePermissions);

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!canEditPermissions) {
				this.status.message =
					!isSysAdmin || (!isManager && submitSitePermissions)
						? `You do not have SiteAdmin or Manager permissions at ${
								this.casinoList.filter((casino) => casino.id == this.selectedCasinoId)[0].name
						  }`
						: "You do not have SysAdmin permissions";
				this.status.ok = false;
				this.status.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				this.selectedCasinoId = null;
				this.addGlobal = this.openPermisionEditor = false;
				this.serverBusy = false;
				return false;
			}

			let body = {
				UserId: this.selectedUser.id,
				Permissions: payload.newPermissions,
			};

			let requestUrl = new URL("/api/v1/user/permissions", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.sitePermissionsArray = this.convertToArrayOfCasinoPermissions(dataJson.sitePermissions);

				this.serverBusy = false;
				this.busyText = "";
				this.selectedCasinoId = null;
				this.addGlobal = this.openPermisionEditor = false;

				let thisUser = this.selectedUser;
				thisUser.permissions = dataJson;
				this.eventBus.emit("updateSelectedUser", thisUser);
				this.status.message = "Permissions Updated";
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		convertToArrayOfCasinoPermissions(sitePermissions) {
			let array = [];
			for (const item in sitePermissions) {
				let casino = this.casinoList.filter((casino) => casino.id == item);
				let permissions = sitePermissions[item];
				array.push({
					casino: casino[0],
					casinoName: casino[0].name,
					permissions: permissions,
				});
			}
			return array.sort((a, b) => a.casinoName.localeCompare(b.casinoName));
		},
		async getUserPermissions() {
			if (!this.selectedUser?.id) return;
			let thisInstance = this;
			this.busyText = `Loading Permissions for ${this.selectedUser?.displayName}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/user/${this.selectedUser.id}/permissions`, this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let thisInstance = this;
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.sitePermissionsArray = this.convertToArrayOfCasinoPermissions(dataJson.sitePermissions);

				let thisUser = this.selectedUser;
				thisUser.permissions = Object.keys(dataJson).length > 0 ? dataJson : {};
				this.eventBus.emit("updateSelectedUser", thisUser);

				// this.populateGlobalOptions();
				this.serverBusy = false;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
	},
};
</script>

<style scoped>
.suspened {
	background-color: #f00;
	color: #fff;
	font-weight: bold;
	padding: 10px;
	width: 90%;
	margin: 20px auto;
	border-radius: 8px;
}

.btn {
	display: block;
	margin: auto;
}

@media (min-width: 768px) {
	.suspened {
		width: 50%;
	}
}
</style>
