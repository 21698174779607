<template>
	<div id="user-transfers" v-if="selectedUser?.phoneNumber">
		<h2>{{ selectedUser?.displayName }} <span v-if="Object.keys(casinoBalance).length > 0">Funds and </span>Transfers</h2>
		<div v-if="Object.keys(casinoBalance).length > 0">
			<table class="casino-balances">
				<tr>
					<th colspan="3">
						{{ selectedUser?.displayName }} Balance at {{ casinoList.filter((casino) => casino.id == selectedCasinoId)[0].name }}
					</th>
				</tr>
				<tr>
					<th>Regular</th>
					<th>Promo</th>
					<th v-if="openFundsLocks.length > 0">Locked funds</th>
				</tr>
				<tr v-if="casinoBalance?.balance?.regularAU || casinoBalance?.balance?.promoAU">
					<td>{{ systemCurrencyTool.formatFromAU(casinoBalance?.balance?.regularAU, systemFull) }}</td>
					<td>{{ systemCurrencyTool.formatFromAU(casinoBalance?.balance?.promoAU, systemFull) }}</td>
					<td class="funds-locks" v-if="openFundsLocks.length > 0">
						{{ systemCurrencyTool.formatFromAU(lockedFundsTotal, systemFull)
						}}<span v-if="openFundsLocks.length > 1"> in {{ openFundsLocks.length }} locks</span>
					</td>
				</tr>
				<tr v-else>
					<td colspan="3">
						{{ selectedUser?.displayName }} has no funds at {{ casinoList.filter((casino) => casino.id == selectedCasinoId)[0].name }}
					</td>
				</tr>
			</table>
		</div>
		<div class="user-transfers-container">
			<div v-if="!!serverBusy" id="transfers-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<div id="paging">
				<button id="refresh" title="Refresh transfers" class="btn" @click="getUserTransfers()">Refresh</button>
				<label v-if="casinoListForUser.length > 1" for="casinoOptions">Casino</label>
				<select v-if="casinoListForUser.length > 1" title="Select Casino" id="casinoOptions" v-model="selectedCasinoId">
					<option v-for="(item, index) in casinoListForUser" :key="index" :value="item.id">{{ item.name }}</option>
				</select>
				<label title="Set Limit" for="limitOptions">Limit List</label>
				<select v-model="limit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
				<button :disabled="serverBusy" class="prev-button" type="button" title="Previous" @click="previousPage()">Previous</button>
				<button :disabled="serverBusy" class="next-button" type="button" title="Next" @click="nextPage()">Next</button>
				<span :currentPage="currentPage">page {{ currentPage }}</span>
			</div>
			<div v-if="selectedCasinoId">
				<p v-if="userTransfers.length > 0" style="text-align: center">
					<span style="font-weight: bold">MRU cash in/out not included in Cashier transactions table.</span>
				</p>
				<UserTransfersTableMobile class="transfers-list" v-if="userTransfers.length > 0 && isMobile" :userTransfers="userTransfers" :systemCurrencyTool="systemCurrencyTool" />
				<UserTransfersTable class="transfers-list" v-if="userTransfers.length > 0 && !isMobile" :userTransfers="userTransfers" :systemCurrencyTool="systemCurrencyTool" />
				<h3 v-if="userTransfers.length <= 0">
					No Transactions found at {{ casinoList.filter((casino) => casino.id == selectedCasinoId)[0].name }} for
					{{ selectedUser?.displayName }}
				</h3>
			</div>
			<h3 v-else>Please select a Casino</h3>
		</div>
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import UserTransfersTableMobile from "@/components/UserTransfersTableMobile";
import UserTransfersTable from "@/components/UserTransfersTable";

export default {
	name: "UserTransfers",
	components: {
		UserTransfersTableMobile,
		UserTransfersTable,
	},
	props: {
		adminState: Object,
		selectedUser: Object,
		casinoList: Array,
		isMobile: Boolean,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			selectedCasinoId: this.adminState.casinoId,
			userTransfers: [],
			serverBusy: false,
			offset: 0,
			currentPage: 1,
			isLastPage: false,
			limit: this.limit,
			casinoBalance: {},
			openFundsLocks: [],
			lockedFundsTotal: 0,
			casinoListForUser: [],
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	watch: {
		selectedCasinoId() {
			if (this.selectedUser?.phoneNumber) this.getUserTransfers();
		},
		selectedUser() {
			if (this.selectedUser?.phoneNumber) this.getUserTransfers();
		},
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.limit = Number(this.limit);
			if (this.selectedUser?.phoneNumber) this.getUserTransfers();
		},
	},
	created() {
		this.eventBus.on("updateSelectedUser", (payload) => {
			if (this.adminState?.userId) this.listCasinosForThisUser();
			if (payload?.phoneNumber) this.getUserTransfers();
		});
		this.eventBus.on("deselectUser", () => {
			this.selectedCasinoId = this.adminState?.casinoId || null;
			this.userTransfers = [];
			this.offset = 0;
			this.currentPage = 1;
			this.isLastPage = false;
			this.casinoBalance = {};
			this.lockedFundsTotal = 0;
			this.openFundsLocks = [];
		});
		onBeforeUnmount(() => {
			this.eventBus.off("deselectUser");
			this.eventBus.off("updateSelectedUser");
		});
	},
	activated() {
		if (this.adminState?.userId) this.listCasinosForThisUser();
		if (this.selectedUser?.phoneNumber) this.getUserTransfers();
	},
	methods: {
		listCasinosForThisUser() {
			let permissionsList = this.adminState?.userPermissions?.sitePermissions;
			let list = [];
			for (const key in permissionsList) {
				if (
					permissionsList[key].includes("Cashier") ||
					permissionsList[key].includes("SiteAdmin") ||
					permissionsList[key].includes("Manager")
				) {
					let casino = this.casinoList.filter((casino) => casino.id == key)[0];
					list.push(casino);
				}
			}
			list = list.sort((a, b) => a.name.localeCompare(b.name));
			this.casinoListForUser = this.adminState.isHelpDesk ? this.casinoList : list;
		},
		getOpenFundslocksAndTotal(locks) {
			this.lockedFundsTotal = 0;
			this.openFundsLocks = [];
			let openLocks = locks.filter((lock) => lock.claimDate === null);
			openLocks.forEach((lock) => {
				this.lockedFundsTotal += lock.valueAU;
				this.openFundsLocks.push(lock);
			});
		},
		async getCurrentBalances() {
			// Check component's isDeactivated property and if server is not already refreshing access token. Return if either true.
			if (this.$.isDeactivated || this.refreshTokenAwait) return;
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/funds/user/${this.selectedUser.id}`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			params.set("casinoId", this.casinoList.filter((casino) => casino.id == this.selectedCasinoId)[0].id);
			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);
				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let data = await response.json();
				// make sure response.json isn't empty array
				// this can happen if a user has not had an account at a particular casino
				if (data.length > 0) {
					this.getOpenFundslocksAndTotal(data[0].fundsLocks);
					return data[0];
				} else {
					return false;
				}

				// casinoBalance.fundsLocks
			} catch (e) {
				console.error(e);
			}
		},
		async getUserTransfers() {
			// Check component's isDeactivated property and if server is not already refreshing access token. Return if either true.
			if (this.$.isDeactivated || this.refreshTokenAwait) return;
			this.busyText = `Loading page ${this.currentPage} Transfers for ${this.selectedUser?.displayName}`;
			this.serverBusy = true;

			// get one more item than requested to see if a second page exists
			let currentLimit = this.limit + 1;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/user/${this.selectedUser.id}/transfers/${this.selectedCasinoId}`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.offset) params.set("offset", this.offset);
			if (this.limit) params.set("limit", currentLimit);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				if (!this.adminState.accessToken || Object.keys(this.selectedUser).length === 0) return false;

				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				if (Array.isArray(dataJson)) {
					dataJson.forEach((item) => {
						item.date = new Date(item.date).toLocaleTimeString([], this.dateOptions);
					});
					this.isLastPage = dataJson?.length <= this.limit;
					// remove extra item if a next page exists
					if (!this.isLastPage) dataJson.pop();
				} else {
					this.status.message = `Server returned code ${dataJson.status || dataJson} but did not return a list`;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.userTransfers = dataJson;

				let selectedPemissions = this.adminState?.userPermissions?.sitePermissions[this.selectedCasinoId.toString()];
				let getBalanceAllowed = selectedPemissions === "Cashier" || this.adminState.isHelpDesk || this.adminState.isSysadmin;

				if (getBalanceAllowed) {
					this.busyText = `Loading balance for ${this.selectedUser?.displayName}`;
					let currentBalances = await this.getCurrentBalances();
					this.casinoBalance = currentBalances ? currentBalances : {};
				} else {
					this.casinoBalance = {};
				}

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.getUserTransfers();
		},
		nextPage() {
			if (this.isLastPage) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.getUserTransfers();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#details-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

.user-message {
	margin-top: 15px;
}

.casino-balances {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.casino-balances {
	margin-bottom: 30px;
}

.funds-locks {
	font-weight: bold;
	color: #8f0000;
}

.casino-balances th,
.casino-balances td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.casino-balances tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
