<template>
	<div id="user-play" v-if="selectedUser?.phoneNumber">
		<div class="filters-applied" title="" v-if="filtersAppliedList.length > 0">
			<strong>Filters applied </strong>
			<small>hover for details: </small>
			<span class="filter-text" v-for="(filter, index) in filtersAppliedList" :key="index" :title="filter.filterValue">
				{{ filter.filterType }}{{ index < filtersAppliedList.length - 1 ? ", " : "" }}
			</span>
		</div>
		<h2>{{ selectedUser?.displayName }} Play Activity</h2>
		<div class="user-play-container">
			<div v-if="!!serverBusy" id="play-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<div id="paging">
				<button id="refresh" title="Refresh play list" class="btn" @click="getUserPlays()">Refresh</button>
				<button id="filters" title="Set Filters" class="btn" @click="showFiltersModal()">Set Filters</button>
				<button v-if="filtersAppliedList.length > 0" id="filters" title="Remove Filters" class="btn" @click="removeFilters()">
					Remove Filters
				</button>
				<div v-if="casinoListForUser.length > 1">
					<label for="casinoOptions">Casino</label>
					<select title="Select Casino" id="casinoOptions" v-model="selectedCasinoId">
						<option v-for="(item, index) in casinoListForUser" :key="index" :value="item.id">{{ item.name }}</option>
					</select>
				</div>
				<label for="limitOptions">Limit List</label>
				<select title="Set Limit" v-model="limit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
				<button :disabled="serverBusy" class="prev-button" type="button" title="Previous" @click="previousPage()">Previous</button>
				<button :disabled="serverBusy" class="next-button" type="button" title="Next" @click="nextPage()">Next</button>
				<span :currentPage="currentPage">page {{ currentPage }}</span>
			</div>
			<PlayActivityTableMobile v-if="playList.length > 0 && isMobile" :playList="playList" :systemCurrencyTool="systemCurrencyTool" />
			<PlayActivityTable v-if="playList.length > 0 && !isMobile" :playList="playList" :systemCurrencyTool="systemCurrencyTool" />
			<h3 v-if="selectedCasinoId && !playList.length > 0 && !filtersAppliedList.length > 0 && !serverBusy">
				No plays found at {{ casinoList.filter((casino) => casino.id == selectedCasinoId)[0].name }} for {{ selectedUser?.displayName }}
			</h3>
			<h3 v-if="selectedCasinoId && !playList.length > 0 && filtersAppliedList.length > 0">
				No plays found with the filters:
				<span class="filter-text" v-for="(filter, index) in filtersAppliedList" :key="index" :title="filter.filterValue">
					{{ filter.filterType }}{{ index < filtersAppliedList.length - 1 ? ", " : "" }}
				</span>
			</h3>
		</div>
		<transition name="slide">
			<PlayActivityDetail
				v-if="playId"
				:adminState="adminState"
				:playId="playId"
				:playList="playList"
				:systemCurrencyTool="systemCurrencyTool"
			/>
		</transition>
		<transition name="fade">
			<KeepAlive>
				<FilterList v-if="showFilters" :allGameTitles="allGameTitles" />
			</KeepAlive>
		</transition>
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import PlayActivityTableMobile from "@/components/PlayActivityTableMobile";
import PlayActivityTable from "@/components/PlayActivityTable";
import PlayActivityDetail from "@/components/PlayActivityDetail";
import FilterList from "@/components/FilterList";

export default {
	name: "PlayActivity",
	components: {
		PlayActivityTableMobile,
		PlayActivityTable,
		PlayActivityDetail,
		FilterList,
	},
	props: {
		adminState: Object,
		selectedUser: Object,
		casinoList: Array,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			offset: 0,
			currentPage: 1,
			isLastPage: false,
			limit: this.limit,
			playList: [],
			selectedCasinoId: this.adminState.casinoId,
			playId: null,
			showFilters: false,
			gameTitle: null,
			minWinAU: null,
			maxWinAU: null,
			startDate: null,
			endDate: null,
			filtersAppliedList: [],
			allGameTitles: [],
			casinoListForUser: [],
		};
	},
	watch: {
		selectedCasinoId() {
			if (this.selectedUser?.id) this.getUserPlays();
		},
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			if (this.selectedUser?.id) this.getUserPlays();
		},
		selectedUser: {
			handler(newUser, oldUser) {
				if (!this.selectedUser?.id) this.playList = [];
				if (this.selectedUser?.id && newUser?.id !== oldUser?.id && !this.$.isDeactivated) {
					this.selectedCasinoId = this.adminState.casinoId;
					this.getUserPlays();
				}
			},
			deep: true,
		},
	},
	created() {
		this.listAllGameTitles();
		this.listCasinosForThisUser();

		let thisInstance = this;
		this.eventBus.on("selectPlayId", (payload) => {
			thisInstance.playId = payload;
		});
		this.eventBus.on("closePlayDetail", () => {
			thisInstance.playId = null;
		});
		this.eventBus.on("applyFilters", (payload) => {
			thisInstance.applyFilters(payload);
			thisInstance.showFilters = false;
		});
		this.eventBus.on("closeFiltersModal", () => {
			thisInstance.showFilters = false;
		});
		this.eventBus.on("deselectUser", () => {
			thisInstance.selectedCasinoId = this.adminState.casinoId;
			thisInstance.offset = 0;
			thisInstance.currentPage = 1;
			thisInstance.isLastPage = false;
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("selectPlayId");
			thisInstance.eventBus.off("closePlayDetail");
			thisInstance.eventBus.off("applyFilters");
			thisInstance.eventBus.off("closeFiltersModal");
			thisInstance.eventBus.off("deselectUser");
		});
	},
	activated() {
		if (this.selectedUser?.phoneNumber) this.getUserPlays();
	},
	methods: {
		listCasinosForThisUser() {
			let permissionsList = this.adminState?.userPermissions?.sitePermissions;
			for (const key in permissionsList) {
				if (permissionsList[key].includes("SiteAdmin") || permissionsList[key].includes("Manager")) {
					let casino = this.casinoList.filter((casino) => casino?.id == key && !casino?.isDeleted)[0];
					if (casino) this.casinoListForUser.push(casino);
				}
			}
		},
		showFiltersModal() {
			this.showFilters = true;
		},
		friendlyFilterDate(date) {
			return new Date(date).toLocaleTimeString([], this.dateOptions);
		},
		checkFiltersApplied(filters) {
			this.filtersAppliedList = [];
			for (const f in filters) {
				if (filters[f]) {
					this.filtersAppliedList.push({
						filterType: f,
						filterValue: f == "startDate" || f == "endDate" ? this.friendlyFilterDate(filters[f]) : filters[f],
					});
				}
			}
		},
		applyFilters(filters) {
			this.gameTitle = filters.gameTitle;
			this.minWinAU = filters.minWinAU;
			this.maxWinAU = filters.maxWinAU;
			this.startDate = filters.startDate;
			this.endDate = filters.endDate;
			this.currentPage = 1;
			this.offset = 0;
			this.checkFiltersApplied(filters);
			if (this.selectedCasinoId) this.getUserPlays();
		},
		removeFilters() {
			this.gameTitle = null;
			this.minWinAU = null;
			this.maxWinAU = null;
			this.startDate = null;
			this.endDate = null;
			this.currentPage = 1;
			this.offset = 0;
			this.checkFiltersApplied();
			if (this.selectedCasinoId) this.getUserPlays();
			this.eventBus.emit("resetFilterComponent");
		},
		async getUserPlays() {
			// Check component's isDeactivated property and if server is not already refreshing access token. Return if either true.
			if (this.$.isDeactivated || this.refreshTokenAwait) return;
			this.busyText = `Loading page ${this.currentPage} Plays for ${this.selectedUser?.displayName}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/play/user/${this.selectedUser.id}`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.gameTitle) params.set("gameTitle", this.gameTitle);
			if (this.minWinAU) params.set("minWinAU", this.minWinAU);
			if (this.maxWinAU) params.set("maxWinAU", this.maxWinAU);
			if (this.startDate) params.set("startDate", this.startDate);
			if (this.endDate) params.set("endDate", this.endDate);

			if (this.offset) params.set("offset", this.offset);

			// get one more item than requested to see if a second page exists
			let currentLimit = this.limit + 1;
			if (currentLimit) params.set("limit", currentLimit);
			if (this.selectedCasinoId) params.set("casinoId", this.selectedCasinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				if (!this.adminState.accessToken || Object.keys(this.selectedUser).length === 0) return false;

				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				if (Array.isArray(dataJson)) {
					this.isLastPage = dataJson?.length <= this.limit;
					// remove extra item if a next page exists
					if (!this.isLastPage) dataJson.pop();
				} else {
					this.status.message = `Server returned code ${dataJson.status || dataJson} but did not return a list`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.playList = dataJson;

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async listAllGameTitles() {
			// if cached, return
			if (sessionStorage.getItem("allGameTitles")) {
				this.allGameTitles = JSON.parse(sessionStorage.getItem("allGameTitles"));
				return true;
			}

			this.busyText = "Loading All Game Titles";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("api/v1/game/titles", this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				this.allGameTitles = dataJson;

				// cache game titles
				sessionStorage.setItem("allGameTitles", JSON.stringify(dataJson));

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			if (this.selectedCasinoId) this.getUserPlays();
		},
		nextPage() {
			if (this.isLastPage || this.playList.length < this.limit) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			if (this.selectedCasinoId) this.getUserPlays();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#play-loading {
	text-align: center;
	font-weight: bold;
	margin: 15px;
}

.play-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.play-list th,
.play-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.details {
	overflow-wrap: anywhere;
}

.play-win {
	position: relative;
	font-weight: bold;
	background-color: #fff;
	color: green;
}

.play-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.play-row {
	cursor: pointer;
	transition: background-color 0.5s ease, color 0.5s ease;
}

tr.play-row:hover {
	background-color: #5b88bf;
	color: white;
}

.filters-applied {
	display: none;
	position: absolute;
	top: 15px;
}

.filter-text {
	color: red;
	cursor: help;
}

.au-block {
	display: flex;
	flex-flow: column nowrap;
}

.locked-win,
.unlocked-win {
	position: absolute;
	padding: 5px 6px;
	background-color: #f00;
	color: #fff;
	top: 0.5625em;
	right: 0.5625em;
	user-select: none;
	font-weight: normal;
	text-transform: uppercase;
	border: 1px #000 solid;
	border-radius: 100%;
	cursor: help;
}

.unlocked-win {
	background-color: #008000;
}

.lock-icon::after {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	left: -5px;
	top: -5px;
	background: #f00
		url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20style%3D%22fill%3A%23FFFFFF%3B%22%20d%3D%22M12%2C6V5c0-2.762-2.238-5-5-5C4.239%2C0%2C2%2C2.238%2C2%2C5v1H1v8h12V6H12z%20M7.5%2C9.848V12h-1V9.848%20C6.207%2C9.673%2C6%2C9.366%2C6%2C9c0-0.553%2C0.448-1%2C1-1s1%2C0.447%2C1%2C1C8%2C9.366%2C7.793%2C9.673%2C7.5%2C9.848z%20M10%2C6H4V5c0-1.657%2C1.343-3%2C3-3%20s3%2C1.343%2C3%2C3V6z%22%2F%3E%3C%2Fsvg%3E")
		no-repeat;
	background-size: 20px;
	background-position: 8px 8px;
	border-radius: 100%;
	border: 1px black solid;
	box-sizing: border-box;
}

@media (min-width: 768px) {
	.filters-applied {
		display: block;
	}
}
</style>
