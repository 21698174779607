<template>
	<div class="play-details">
		<div class="play-detail-container">
			<div class="grid-container">
				<div
					class="grid-item"
					v-for="(card, index) in cards"
					:key="index"
					:value="card.draw"
					:id="`card${index}`"
					:class="card.hit ? 'hit-spot' : ''"
				>
					{{ card.draw }}
				</div>
			</div>
			<div class="details-summary-comtainer">
				<p class="flex-column">
					<span><strong>Game ID:</strong> {{ playDetails.gameId }}</span>
					<span><strong>Game Title:</strong> {{ playDetails.gameTitle }}</span>
					<span><strong>Play Date:</strong> {{ new Date(playDetails.resolvedTime).toLocaleTimeString([], dateOptions) }}</span>
				</p>

				<p class="flex-column indent">
					<strong>Wager:</strong>
					<span>cash: {{ systemCurrencyTool.formatFromAU(playDetails.wager.regularAU, systemFull) }}</span>
					<span>Promo: {{ systemCurrencyTool.formatFromAU(playDetails.wager.promoAU, systemFull) }}</span>
				</p>
				<p class="flex-column indent">
					<strong
						>Total Win: {{ systemCurrencyTool.formatFromAU(playDetails.totalWinAU, systemFull) }}
						<span v-if="playDetails.forcedOutcomeType !== 'None'" class="forced">- Forced Outcome</span>
					</strong>
					<span>Paytable: {{ systemCurrencyTool.formatFromAU(playDetails.prize.creditsAU, systemFull) }}</span>
					<span v-if="playDetails.prize.progressiveTierBits !== 0">
						<span class="progressive">Progressive: </span>
						<span v-for="(prog, index) in playDetails.progressiveClaims" :key="index">{{
							systemCurrencyTool.formatFromAU(prog.creditsAU, systemFull)
						}}</span>
					</span>
				</p>
				<p v-if="playDetails.forcedOutcomeType !== 'None'" class="flex-column indent">
					<strong>Forced Outcome Type:</strong>
					<span>{{ playDetails.forcedOutcomeType }}</span>
				</p>
				<p class="flex-column indent" v-if="playDetails?.fundsLock?.id">
					<strong>Funds Lock Id: {{ playDetails.fundsLock.id }}</strong>
					<span>Value: {{ systemCurrencyTool.formatFromAU(playDetails.fundsLock.valueAU, systemFull) }}</span>
					<span v-if="playDetails.fundsLock.claimDate"
						>Claim Date: {{ new Date(playDetails.fundsLock.claimDate).toLocaleTimeString([], dateOptions) }}</span
					>
					<span v-else><strong>Unclaimed</strong></span>
				</p>
				<p class="flex-column indent">
					<strong>Ending Balance:</strong>
					<span>Cash: {{ systemCurrencyTool.formatFromAU(playDetails.endingCreditBalance.regularAU, systemFull) }}</span>
					<span>Promo: {{ systemCurrencyTool.formatFromAU(playDetails.endingCreditBalance.promoAU, systemFull) }}</span>
				</p>
				<p class="flex-column indent">
					<strong>Bet Details:</strong>
					<span v-if="isWaysGame">Ways Game</span>
					<span v-else>Number of Lines: {{ playDetails.reelsPlayDetails.numberOfLines }}</span>
					<span>Base Bet: {{ playDetails.reelsPlayDetails.baseBet }}</span>
					<span
						>Denomination:
						{{ systemCurrencyTool.formatFromAU(playDetails.reelsPlayDetails.denominationAU, systemMinorWholeOrFull) }}</span
					>
					<span>Percent of Max Bet: {{ playDetails.reelsPlayDetails.percentOfMaxBet }}</span>
				</p>
			</div>
			<div>
				<p class="flex-column">
					<span><strong>Pattern:</strong> {{ patternMatchedBitfield }}</span>
					<span><strong>BallsDrawn:</strong> {{ playDetails.bingoPlayDetails.ballsDrawnCount }}</span>
				</p>
				<p>
					<strong>Ball Draw: </strong>
					<span v-for="(ball, index) in playDetails.bingoPlayDetails.ballDraw" :key="index" :class="highlightType(ball, index)"
						>{{ ball }}{{ index < playDetails.bingoPlayDetails.ballDraw.length - 1 ? ", " : "" }}</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CardSpotDetails",
	inheritAttrs: false,
	props: {
		playDetails: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			cardSpotValues: this.playDetails.bingoPlayDetails.cardSpotValues,
			patternMatchedBitfield: this.playDetails.bingoPlayDetails.patternMatchedBitfield,
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
			cards: [],
			isWaysGame: false,
		};
	},
	created() {
		this.isWaysGame = this.playDetails.reelsPlayDetails.numberOfLines === 0;
		this.cardSpotValues.forEach((spot, index) => {
			let card = {
				draw: spot,
				hit: (this.patternMatchedBitfield & (1 << index)) != 0 ? true : false,
			};
			this.cards.push(card);
		});
	},
	methods: {
		highlightType(ball, index) {
			let highlightClassName = [];
			if (index <= this.playDetails.bingoPlayDetails.ballsDrawnCount - 1) highlightClassName.push("highlight");
			if (this.cardSpotValues.includes(ball)) highlightClassName.push("ball-hit");
			return highlightClassName.join(" ");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
	text-align: center;
}

hr {
	background-color: #000;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.grid-container {
	display: grid;
	justify-content: center;
	justify-items: center;
	align-content: center;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(5, 1fr);
	grid-gap: 5px;
	background-color: #bbb;
	padding: 15px;
	border: 1px #000 solid;
	border-radius: 10px;
	box-shadow: 2px 3px 6px rgb(0 0 0 / 40%);
}

.reels-play-details-container > div {
	margin-bottom: 8em;
}

.spin-container {
	margin-bottom: 15px;
}

hr.divider {
	border-width: 3px;
}

.grid-item {
	display: grid;
	justify-content: center;
	align-content: center;
	font-size: 1.5rem;
	box-sizing: border-box;
	border: 1px solid #000;
	background-color: rgb(255, 255, 255);
	padding: 5px;
	height: 2em;
	width: 2em;
}

.hit-spot {
	border-radius: 100%;
	border: 3px red solid;
}

.play-detail-container {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: center;
}

.play-detail-container .grid-container {
	user-select: none;
}

.play-detail-container > div,
.reels-ball-draw > * {
	margin: 15px;
	align-self: center;
}

.indent > span {
	margin-left: 15px;
}

.highlight {
	background-color: yellow;
}

.ball-hit {
	font-weight: bold;
}

.forced {
	color: #f00;
	font-weight: bold;
	text-transform: uppercase;
}
</style>
