<template>
	<table v-for="(item, index) in allMrus" :key="index" class="player-mrus">
		<tr>
			<th>Player</th>
			<td :class="item.status.toLowerCase()">{{ selectedUser.displayName }}</td>
		</tr>
		<tr>
			<th>MRU Name</th>
			<td :class="item.status.toLowerCase()">{{ item.mruName }}</td>
		</tr>
		<tr>
			<th>MRU Location</th>
			<td :class="item.status.toLowerCase()">{{ item.mruLocation }}</td>
		</tr>
		<tr>
			<th>MRU Status</th>
			<td v-if="item.status === 'Pending'" class="mru-status" :class="item.status.toLowerCase()">
				<button class="btn resolve" title="Click to resolve this pending MRU" @click="resolveMruModal(item)">
					{{ item.status }}
				</button>
			</td>
			<td v-else class="mru-status" :class="item.status.toLowerCase()">{{ item.status }}</td>
		</tr>
		<tr>
			<th>Cash <span class="in">In</span>/<span class="out">Out</span></th>
			<td
				class="cash-in-out-amount"
				:class="Math.sign(item.toAccountAmountAU) === 1 ? 'cash-in' : 'cash-out'"
				:title="Math.sign(item.toAccountAmountAU) === 1 ? 'Cash-in transaction' : 'Cash-out transaction'"
			>
				{{ toLocaleCurrencyIntl(item.toAccountAmountAU) }}
			</td>
		</tr>
		<tr>
			<th>Initiation Date</th>
			<td :class="item.status.toLowerCase()">{{ new Date(item.initiationDate).toLocaleTimeString([], this.dateOptions) }}</td>
		</tr>
		<tr>
			<th>Resolution Date</th>
			<td v-if="item.resolutionDate" :class="item.status.toLowerCase()">
				{{ new Date(item.resolutionDate).toLocaleTimeString([], this.dateOptions) }}
			</td>
			<td v-else :class="item.status.toLowerCase()">Not Resolved</td>
		</tr>
	</table>
	<transition name="fade">
		<component v-bind:is="currentModal" :selectedMru="selectedMru" />
	</transition>
</template>

<script>
import { onBeforeUnmount } from "vue";
import ResolveMru from "@/components/ResolveMru";

export default {
	name: "MruResolutionTable",
	components: {
		ResolveMru,
	},
	props: {
		selectedUser: Object,
		allMrus: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			currentModal: null,
			selectedMru: {},
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("closeMruResolveModal", () => {
			thisInstance.currentModal = null;
			this.stopBodyScroll(false);
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("closeMruResolveModal");
		});
	},
	methods: {
		resolveMruModal(mru) {
			this.selectedMru = mru;
			this.currentModal = "ResolveMru";
			this.stopBodyScroll(true);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player-mrus {
	position: relative;
	width: 100%;
	text-align: left;
	border-collapse: collapse;
	margin: 15px auto 30px;
	box-shadow: 2px 2px 8px #000;
}

.player-mrus th,
.player-mrus td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.player-mrus th {
	color: #fff;
	background-color: #000;
}

.mru-status {
	text-align: center;
}

.cash-in-out-amount {
	text-align: right;
}

.cash-out {
	background-color: #00d0ff;
	color: #ad0000;
	font-weight: bold;
	cursor: help;
}

.cash-in {
	background-color: #00ffa1;
	cursor: help;
}

.in {
	color: #00ffa1;
}

.out {
	color: #00d0ff;
}

.resolve {
	font-size: 1em;
}

#mrus-loading {
	display: grid;
	align-content: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 80%);
	color: #fff;
	text-align: center;
	z-index: 200;
}

.player-mrus td.cancelled {
	background-color: rgb(255 189 69 / 50%) !important;
}

.player-mrus td.pending {
	background-color: rgb(241 243 113 / 30%) !important;
	color: #000;
}

.loading-icon {
	margin: 0 auto 10px;
}

.player-mrus tr:nth-child(2n) {
	background-color: #bfbfbf;
}
@media (min-width: 768px) {
	#date-filters {
		flex-direction: row;
	}
}
</style>
