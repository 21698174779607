<template>
	<div class="audit-note-text">
		<div class="audit-note-container">
			<fieldset>
				<legend>Add Audit Note</legend>
				<label style="margin: 5px 0" for="auditNoteText">Enter Audit Note Text</label>
				<textarea
					type="text"
					name="auditNoteText"
					placeholder="Enter Audit Note Text"
					title="Enter Audit Note Text"
					@keyup.enter="addUserNote()"
					@keypress.enter.prevent
					v-model="auditNoteText"
				></textarea>
				<button v-if="selectedUser?.phoneNumber" @click="addUserNote()" title="Add User Note" class="add-note btn">Add User Note</button>
				<button @click="closeModal()" title="Close" class="close-modal btn">Close</button>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "AddUserNote",
	props: {
		adminState: Object,
		selectedUser: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			body: document.querySelector("body"),
			auditNoteText: "",
		};
	},
	created() {
		onBeforeUnmount(() => {
			this.body.onkeydown = null;
		});
	},
	mounted() {
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") this.closeModal();
		};
	},
	methods: {
		async addUserNote() {
			this.busyText = "Saving Audit Note";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				UserId: this.selectedUser.id,
				NoteText: this.auditNoteText,
			};

			let requestUrl = new URL("/api/v1/user/audit", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				this.status.ok = true;
				this.status.message = `Note added to User Logs for ${this.selectedUser.phoneNumber}`;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateAuditLogs");
				this.serverBusy = false;
				this.closeModal();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		closeModal() {
			this.eventBus.emit("closeAddNoteModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.audit-note-text {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(5px);
	z-index: 100;
}

.audit-note-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 80%;
	margin: auto;
	padding: 30px;
	border-radius: 12px;
	text-align: center;
}

.audit-note-container fieldset {
	display: flex;
	flex-flow: column;
	padding: 30px;
}

.audit-note-container button {
	margin: 5px auto;
}
@media (min-width: 768px) {
	.audit-note-container {
		width: 50%;
	}
}
</style>
