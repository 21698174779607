<template>
	<div id="mruResolution" class="input-heading" v-if="selectedUser?.phoneNumber">
		<h2>Mru Resolution</h2>
		<h3>{{ casinoListForUser[selectedCasinoId - 1]?.name }}</h3>
		<div>
			<div v-if="serverBusy" id="mrus-loading">
				<span class="loading-icon"></span>
				{{ busyText }}
			</div>
			<div id="paging">
				<button id="refresh" title="Refresh MRU Transactions" class="btn" @click="getUserMruTransactions()">Refresh</button>
				<label v-if="casinoListForUser.length > 1" for="casinoOptions">Casino</label>
				<select v-if="casinoListForUser.length > 1" title="Select Casino" id="casinoOptions" v-model="selectedCasinoId">
					<option v-for="(item, index) in casinoListForUser" :key="index" :value="item.id">{{ item.name }}</option>
				</select>
				<label for="limitOptions">Limit List</label>
				<select v-model="mruLimit" id="limitOptions">
					<option v-for="(item, index) in extendedLimitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
				<button :disabled="serverBusy" class="prev-button" type="button" @click="previousPage($event)">previous</button>
				<button :disabled="serverBusy" class="next-button" type="button" @click="nextPage($event)">next</button>
				<span :currentPage="currentPage">page {{ currentPage }}</span>
			</div>
			<div id="date-filters">
				<div>
					<label for="startDate">Start Date</label>
					<input
						id="startDate"
						:class="errorState ? 'date-error' : ''"
						:min="startMin"
						:max="startMax"
						type="datetime-local"
						placeholder="Start Date"
						title="Start Date"
						v-model="startDate"
					/>
					<span class="clear-item" title="Clear this filter" @click="startDate = null">x</span>
				</div>
				<div>
					<label for="endDate">End Date</label>
					<input
						id="endDate"
						:class="errorState ? 'date-error' : ''"
						:min="endMin"
						:max="endMax"
						type="datetime-local"
						placeholder="End Date"
						title="End Date"
						v-model="endDate"
					/>
					<span class="clear-item" title="Clear this filter" @click="endDate = null">x</span>
				</div>
				<button @click="applyFilters()" title="Apply all filters" class="apply-filters btn">Apply Filters</button>
			</div>
		</div>
		<div class="mru-lists-container">
			<MruResolutionTable
				v-if="allMrus.length > 0 && !isMobile"
				:selectedUser="selectedUser"
				:allMrus="allMrus"
				:systemCurrencyTool="systemCurrencyTool"
			/>
			<MruResolutionTableMobile
				v-if="allMrus.length > 0 && isMobile"
				:selectedUser="selectedUser"
				:allMrus="allMrus"
				:systemCurrencyTool="systemCurrencyTool"
			/>
			<div v-if="!allMrus.length > 0 && !serverBusy">
				<h2>
					No MRU transactions found for this player
					<span v-if="selectedCasinoId">at {{ casinoList.filter((casino) => casino.id == selectedCasinoId)[0].name }}</span
					>.
				</h2>
			</div>
		</div>
	</div>
	<div v-else>
		<h2>No User Selected</h2>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import MruResolutionTable from "@/components/MruResolutionTable";
import MruResolutionTableMobile from "@/components/MruResolutionTableMobile";

export default {
	name: "MruResolution",
	components: {
		MruResolutionTable,
		MruResolutionTableMobile,
	},
	props: {
		adminState: Object,
		casinoList: Array,
		isMobile: Boolean,
		selectedUser: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			// make a unique copy of the global limitOptions...
			// Add an option for 1000 entries since MRU does one transaction per bill insert.
			// Someone might want to see that many to scan for unresolved transactions.
			extendedLimitOptions: [...this.limitOptions, { text: "1000", value: 1000 }],
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
			errorState: false,
			busyText: "",
			serverBusy: false,
			selectedCasinoId: null,
			casinoListForUser: [],
			currentModal: null,
			mruLimit: this.limit,
			mruOffset: null,
			currentPage: 1,
			isLastPage: false,
			startDate: null,
			endDate: null,
			startMin: null,
			startMax: null,
			endMin: null,
			endMax: null,
			allMrus: [],
			selectedMru: {},
			componentMounted: false,
		};
	},
	watch: {
		selectedCasinoId() {
			this.currentPage = 1;
			this.mruOffset = 0;
			if (this.selectedUser?.phoneNumber) this.getUserMruTransactions();
		},
		selectedUser() {
			this.currentPage = 1;
			this.mruOffset = 0;
			if (this.selectedUser?.phoneNumber) this.getUserMruTransactions();
			this.listCasinosForThisUser();
		},
		mruLimit() {
			this.currentPage = 1;
			this.mruOffset = 0;
			if (this.selectedUser?.phoneNumber) this.getUserMruTransactions();
		},
		startDate() {
			this.endMin = this.startDate;
		},
		endDate() {
			this.startMax = this.endDate;
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("resolveMRU", (mru) => {
			thisInstance.resolveMru(mru);
		});
		this.eventBus.on("deselectUser", () => {
			thisInstance.componentMounted = false;
			thisInstance.casinoListForUser = [];
			thisInstance.allMrus = [];
			thisInstance.selectedMru = {};
			thisInstance.mruOffset = 0;
			thisInstance.currentPage = 1;
			thisInstance.isLastPage = false;
			thisInstance.currentModal = null;
			thisInstance.selectedCasinoId = this.adminState.casinoId;
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("resolveMRU");
			thisInstance.eventBus.off("deselectUser");
		});
		this.listCasinosForThisUser();
		this.selectedCasinoId = this.casinoListForUser[0].id;
	},
	activated() {
		if (this.selectedUser?.phoneNumber) this.getUserMruTransactions();
	},
	methods: {
		applyFilters() {
			let errState = false;
			this.filters = {
				gameTitle: this.gameTitle,
				minWinAU: this.minWinAU,
				maxWinAU: this.maxWinAU,
				startDate: this.startDate ? this.formatDateTimeISOString(this.startDate) : null,
				endDate: this.endDate ? this.formatDateTimeISOString(this.endDate) : null,
			};

			if (document.getElementById("startDate").validity.badInput) {
				this.throwDateTimeError("Start Date");
				errState = true;
			}

			if (document.getElementById("endDate").validity.badInput) {
				this.throwDateTimeError("End Date");
				errState = true;
			}

			if (errState) return false;

			if (this.selectedCasinoId && this.selectedUser?.phoneNumber) this.getUserMruTransactions();
		},
		throwDateTimeError(input) {
			this.status.message = `${input} must have both the date and time or be left unset/empty.`;
			this.status.ok = false;
			this.eventBus.emit("updateStatus", this.status);
		},
		listCasinosForThisUser() {
			let permissionsList = this.adminState?.userPermissions?.sitePermissions;
			let list = [];
			for (const key in permissionsList) {
				if (
					permissionsList[key].includes("SiteAdmin") ||
					permissionsList[key].includes("Cashier") ||
					permissionsList[key].includes("Manager")
				) {
					let casino = this.casinoList.filter((casino) => casino.id == key)[0];
					list.push(casino);
				}
			}
			this.casinoListForUser = this.adminState?.isHelpDesk ? this.casinoList : list;
		},
		async getUserMruTransactions(mruLimit = null, mruOffset = null) {
			// Check component's isDeactivated property and if server is not already refreshing access token. Return if either true.
			if (this.$.isDeactivated || this.refreshTokenAwait) return;
			this.serverBusy = true;
			this.busyText = "Loading MRUs";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let startDate = this.startDate ? this.formatDateTimeISOString(this.startDate) : null;
			let endDate = this.endDate ? this.formatDateTimeISOString(this.endDate) : null;

			let limit = mruLimit || this.mruLimit;
			let offset = mruOffset || this.mruOffset;
			this.getListComplete = false;
			this.cashierBankId = this.cashierBankId || this.adminState.cashierBankId;

			if (!this.selectedUser?.id) return;

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`api/v1/user/${this.selectedUser.id}/mru/${this.selectedCasinoId}`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (startDate) params.set("startDate", startDate);
			if (endDate) params.set("endDate", endDate);
			if (offset) params.set("offset", offset);

			// get one more item than requested to see if a second page exists
			if (limit) params.set("limit", Number(limit) + 1);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				this.serverBusy = true;
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				var dataJson = await response.json();

				this.isLastPage = dataJson?.length <= limit;
				// remove extra item if a next page exists
				if (!this.isLastPage) dataJson.pop();

				this.serverBusy = false;
				this.busyText = "";

				dataJson.sort((a, b) => (a.initiationDate > b.initiationDate ? -1 : 1));

				this.allMrus = dataJson;
			} catch (e) {
				this.busyText = "";
				console.error(e);
				this.status.message = e;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
			}
		},
		async previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.mruOffset = this.mruOffset - this.mruLimit;
			if (this.selectedCasinoId && this.selectedUser?.phoneNumber) this.getUserMruTransactions(this.mruLimit, this.mruOffset);
		},
		async nextPage() {
			if (this.isLastPage) return;
			this.mruOffset = this.mruOffset + this.mruLimit;
			this.currentPage++;
			if (this.selectedCasinoId && this.selectedUser?.phoneNumber) this.getUserMruTransactions(this.mruLimit, this.mruOffset);
		},
		async resolveMru(mru) {
			this.busyText = `${mru.payload.Action === "Commit" ? "Committing" : "Cancelling"} transaction for ${this.toLocaleCurrencyIntl(
				mru.toAccountAmountAU
			)}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = mru.payload;

			let requestUrl = new URL("/api/v1/mru/adjust/resolve", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let thisInstance = this;
				let thisMru = thisInstance.allMrus.filter((item) => item.id === mru.id)[0];

				let responseJson = await response.json();

				responseJson = sharedScripts.checkSuccessErrors(responseJson.status);

				if (responseJson.ok) {
					let action = mru.payload.Action === "Commit" ? "Committed" : "Cancelled";
					thisMru.status = action;
					thisMru.resolutionDate = new Date();
					this.status.ok = true;
					this.status.message = `Transaction for ${this.toLocaleCurrencyIntl(mru.toAccountAmountAU)} was ${action}`;
					this.eventBus.emit("updateStatus", this.status);
				} else {
					this.eventBus.emit("updateStatus", responseJson);
				}

				this.busyText = "";
				this.serverBusy = false;
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mrus-loading {
	text-align: center;
	font-weight: bold;
	margin: 15px;
}

.mru-lists-container {
	position: relative;
}

#date-filters {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#date-filters div {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

#date-filters button {
	width: 8em;
}

.clear-item {
	align-self: center;
	justify-self: center;
	background-color: #5a87bf;
	color: #fff;
	padding: 0px 5px 2px;
	margin: 0;
	border-radius: 100%;
	cursor: pointer;
	font-size: 0.8em;
}

.date-error {
	border: 3px red solid;
}

@media (min-width: 768px) {
	#date-filters {
		flex-direction: row;
	}

	#date-filters button {
		width: auto;
	}
}
</style>
