<template>
	<div id="id-check-review">
		<div v-if="idCheckResults.length > 0">
			<h2>Id Check Reviews</h2>
			<table class="idCheck-list" v-for="(item, index) in idCheckResults" :key="index">
				<tr>
					<th>Id</th>
					<td>{{ item.id }}</td>
				</tr>
				<tr>
					<th>Provider Id</th>
					<td>
						<a
							v-if="item.provider === 'NeoCheck'"
							:href="`${neoCheckLookupURL}${item.providerId}`"
							title="Click to view this item on NeoCheck site"
							target="_blank"
							>{{ item.providerId }}</a
						>
						<span v-else>{{ item.providerId }}</span>
					</td>
				</tr>
				<tr>
					<th>Provider</th>
					<td>{{ item.provider }}</td>
				</tr>
				<tr>
					<th>Date</th>
					<td>{{ new Date(item.date).toLocaleTimeString([], this.dateOptions) }}</td>
				</tr>
				<tr>
					<th>Status</th>
					<td>{{ item.status }}</td>
				</tr>
				<tr>
					<th>Passed General Approval</th>
					<td>{{ item.passedGeneralApproval }}</td>
				</tr>				<tr>
					<th>Passed Adress Check</th>
					<td>{{ item.passedAdressCheck }}</td>
				</tr>
				<tr>
					<th>Passed Id Document Check</th>
					<td>{{ item.passedIdDocumentCheck }}</td>
				</tr>
				<tr>
					<th>Id Document Type</th>
					<td>{{ item.idDocumentType }}</td>
				</tr>
				<tr>
					<th>Id Document Number</th>
					<td>{{ item.idDocumentNumber }}</td>
				</tr>
			</table>
		</div>
		<div v-else>
			<h2 v-if="!serverBusy">No Id Check Reviews Found</h2>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts"; // https://neocheck.net/#/videoIdentifications/e6d6b440-42c8-4561-d92d-08dc8a458e68/results

export default {
	name: "IdCheckReview",
	inheritAttrs: false,
	props: {
		adminState: Object,
		selectedUser: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			neoCheckLookupURL: "https://neocheck.net/#/videoIdentifications/",
			busyText: "",
			serverBusy: false,
			startDate: null,
			endDate: null,
			idCheckResults: [],
		};
	},
	watch: {},
	created() {
		this.getUserIdCheckResult();
	},
	mounted() {},
	methods: {
		async getUserIdCheckResult() {
			// Check component's if server is not already refreshing access token. Return if true.
			if (this.refreshTokenAwait) return;
			this.busyText = "Loading Id Check Results";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/idcheck/user/${this.selectedUser.id}`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.startDate) params.set("startDate", this.startDate);
			if (this.endDate) params.set("endDate", this.endDate);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverStatus.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.idCheckResults = dataJson;

				this.serverBusy = false;
				this.serverStatus.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table.idCheck-list {
	margin: 15px auto;
	background-color: #ddd;
}

th,
td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
